import React from "react";

function Tendency() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 637.603 599.703"
    >
      <path
        fill="#a0616a"
        d="M562.736 262.629a9.503 9.503 0 00-9.508-9.234 9.116 9.116 0 00-1.5.15l-16.62-28.684-17.523 3.312 27.017 36.833a9.476 9.476 0 009.177 7.044 9.069 9.069 0 008.963-9.172q-.001-.125-.006-.25z"
      ></path>
      <path
        fill="#a0616a"
        d="M506.295 581.028L492.409 581.026 485.802 527.463 506.298 527.464 506.295 581.028z"
      ></path>
      <path
        fill="#2f2e41"
        d="M506.784 595.514l-42.703-.002v-.54a16.622 16.622 0 0116.621-16.62h.001l7.8-5.918 14.554 5.918h3.728z"
      ></path>
      <path
        fill="#a0616a"
        d="M520.928 501.241L511.57 511.5 467.542 480.287 481.354 465.144 520.928 501.241z"
      ></path>
      <path
        fill="#2f2e41"
        d="M531.961 510.64l-28.777 31.552-.399-.364a16.622 16.622 0 01-1.08-23.481v-.001l.884-9.751 14.18-6.765 2.512-2.754zM417.016 225.276L391 272.646l2 34-11 110 103 86 13-19-58-74 .695-5.247a7.638 7.638 0 011.322-9.972c4.983-4.781.748-5.645.748-5.645L451 326.646l59-11.795-24.748-93.382z"
      ></path>
      <path
        fill="#2f2e41"
        d="M452 292.646l31 261 27 2.716v-91.218s-5-4.963 0-7.23 0-10.772 0-10.772V314.85l-25-39.205z"
      ></path>
      <path
        fill="#3f3d56"
        d="M397 74.646l-24 9-14 32s-32 16-20 38 60.287 70.287 60.287 70.287c-5.907 11.524-3.886 20.389 5.713 26.713 12.533 20.102 47.722 10.254 87-5 7.269-17.725-3.984-45.177-15.045-72.528L480 136.646s-34.496-50.197-40-50-27-2-27-2z"
      ></path>
      <path
        fill="#3f3d56"
        d="M431 91.748a19.239 19.239 0 0129.499 3.698l52.314 82.873c5.02 2.378 7.544 7.74 9.043 14.327 3.729-1.265 6.553 1.194 7.413 11.743l19.73 31.257-18 20-24.893-33c-5.788-1.53-3.097-9.825-7.101-9.413s-5.941-7.874-5.941-7.874c-5.051-.057-6.535-2.427-5.064-6.713l-51-72.882z"
      ></path>
      <path
        fill="#a0616a"
        d="M208.55 89.883a9.503 9.503 0 0011.723 6.184 9.116 9.116 0 001.396-.566l24.022 22.847 15.882-8.11-36.29-27.74a9.476 9.476 0 00-10.79-4.177 9.069 9.069 0 00-6.02 11.324q.037.12.077.238z"
      ></path>
      <path
        fill="#3f3d56"
        d="M357 124.646l-33 10-23.26-3.697-5.217-3.727c-2.247-6.075-6.774-8.931-12.807-9.663 0 0-4.88 1.672-4.798-3.62S246 89.645 246 89.645l-14 25 18 22c6.39.197 10.327-.646 7 7 1.358 7.363.419 5.654 5.903 5.788L291 177.646l59 9 37.472-13.2z"
      ></path>
      <circle cx="365.21" cy="42.757" r="30.716" fill="#a0616a"></circle>
      <path
        fill="#2f2e41"
        d="M384.918 42.163q-.04-.513-.106-1.027a2.303 2.303 0 00.106 1.027z"
      ></path>
      <path
        fill="#2f2e41"
        d="M322.464 21.673c-.54-3.686 1.513-7.863 5.102-8.885 2.236-.638 4.622-.026 6.941.014 6.507.11 18.07-10.73 24.351-12.39 6.292-1.657 14.435 1.804 14.944 8.29 16.09-4.79 28.376 6.048 29.126 23.14.587 13.368-10.782 31.588-22.77 37.534-1.469-7.779 3.968-19.439 2.504-27.21a15.754 15.754 0 00-2.03-6.07c-1.2-1.782-3.403-3.07-5.485-2.543a3.542 3.542 0 00-2.465 4.102 14.449 14.449 0 00-24.167-1.12l1.697-6.988a33.623 33.623 0 01-16.052 9.82 4.462 4.462 0 01-.78-1.481 3.364 3.364 0 01.662-3.245 7.791 7.791 0 00-2.982 5.346 15.316 15.316 0 01-10.738-2.112c-3.084-2.1 2.681-12.517 2.142-16.202z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M603.11 598.678h-33.187l-.143-.259a36.166 36.166 0 01-1.216-2.422c-3.419-7.319-4.864-15.689-6.139-23.074l-.96-5.566a3.437 3.437 0 015.41-3.362q7.566 5.505 15.137 10.999c1.911 1.39 4.094 3 6.184 4.739.202-.98.413-1.962.623-2.93a3.44 3.44 0 016.281-1.087l3.883 6.239c2.832 4.555 5.332 9.044 4.822 13.886a.757.757 0 01-.013.176 10.947 10.947 0 01-.563 2.331z"
      ></path>
      <path
        fill="#cacaca"
        d="M636.419 599.396l-315.358.307a1.19 1.19 0 010-2.381l315.358-.307a1.19 1.19 0 010 2.381z"
      ></path>
      <path
        fill="#fff"
        d="M276 472.646H23a23.026 23.026 0 01-23-23v-340a23.026 23.026 0 0123-23h253a23.026 23.026 0 0123 23v340a23.026 23.026 0 01-23 23z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M276 472.646H23a23.026 23.026 0 01-23-23v-340a23.026 23.026 0 0123-23h253a23.026 23.026 0 0123 23v340a23.026 23.026 0 01-23 23zm-253-384a21.024 21.024 0 00-21 21v340a21.024 21.024 0 0021 21h253a21.024 21.024 0 0021-21v-340a21.024 21.024 0 00-21-21z"
      ></path>
      <circle cx="60.5" cy="189.899" r="9" fill="#ccc"></circle>
      <path
        fill="#ccc"
        d="M245.5 190.9H91.256a1 1 0 010-2H245.5a1 1 0 010 2z"
      ></path>
      <circle cx="61" cy="356.899" r="9" fill="#ccc"></circle>
      <circle cx="60.561" cy="261.499" r="9" fill="#ccc"></circle>
      <path
        fill="#0fd24f"
        d="M243.5 215.499h-40a4 4 0 010-8h40a4 4 0 010 8zM243.5 314.499h-40a4 4 0 010-8h40a4 4 0 010 8zM214.697 140.9H99.803a10.553 10.553 0 010-21.107h114.894a10.553 10.553 0 110 21.106zM243.5 439.499h-40a4 4 0 110-8h40a4 4 0 010 8z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M235.71 414.9H101.14a11.114 11.114 0 01-2.52-.32A11.248 11.248 0 0190 403.61v-45.74a11.298 11.298 0 0111.29-11.28h134.42a11.144 11.144 0 015.203 1.273 10.813 10.813 0 011.804 1.177 11.165 11.165 0 014.283 8.83v45.74a11.303 11.303 0 01-11.29 11.29zm-134.42-66.31a9.296 9.296 0 00-9.29 9.28v45.74a9.257 9.257 0 007.095 9.028 9.133 9.133 0 002.057.261H235.71a9.3 9.3 0 009.29-9.29v-45.74a9.185 9.185 0 00-3.528-7.264 8.86 8.86 0 00-1.48-.967 9.169 9.169 0 00-4.282-1.049z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M226.69 413.9q9.165-22.276 19.31-44.11v24.68a119.899 119.899 0 01-15.92 19.43zM242.09 349.82q-18.135 32.534-37.95 64.08h-2.3q20.145-32.04 38.61-65.15a9.829 9.829 0 011.64 1.07zM153.37 373.5a407.715 407.715 0 01-26.11 40.4h-13.71q13.47-16.41 26.96-32.82c2.58-3.15 5.38-6.45 9.25-7.73z"
      ></path>
      <path
        fill="#0fd24f"
        d="M135.15 371.26c-1.43 1.49-2.8 3.04-4.09 4.65-2.84 3.42-5.64 6.87-8.45 10.31-1.75 2.14-3.5 4.28-5.23 6.42a12.77 12.77 0 01-1.56-1.18 13.993 13.993 0 0117.88-21.52 12.898 12.898 0 011.45 1.32z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M212.48 380.98l-26.28-9.61-13.05-4.77c-4.25-1.55-8.49-3.32-12.88-4.44a20.305 20.305 0 00-11.66.15 26.808 26.808 0 00-9.09 5.64q-1.74 1.604-3.37 3.31c-1.43 1.49-2.8 3.04-4.09 4.65-2.84 3.42-5.64 6.87-8.45 10.31-1.75 2.14-3.5 4.28-5.23 6.42q-8.67 10.604-17.24 21.26a10.022 10.022 0 01-2.28-.29q8.94-11.1 17.96-22.15c.69-.86 1.4-1.73 2.1-2.59q4.395-5.386 8.8-10.76c2.27-2.77 4.52-5.56 6.98-8.17.22-.24.44-.47.67-.71 4.99-5.16 11.22-9.72 18.68-9.84 4.39-.07 8.63 1.32 12.71 2.79 4.39 1.59 8.77 3.21 13.16 4.82l26.46 9.68 6.62 2.42c1.17.42.67 2.32-.52 1.88zM127.038 289.69H95.849a6.295 6.295 0 01-6.288-6.287v-29.176a6.295 6.295 0 016.288-6.288h31.189a6.295 6.295 0 016.288 6.288v29.176a6.295 6.295 0 01-6.288 6.288zM182.669 289.69H151.48a6.295 6.295 0 01-6.288-6.287v-29.176a6.295 6.295 0 016.288-6.288h31.189a6.295 6.295 0 016.288 6.288v29.176a6.295 6.295 0 01-6.288 6.288zM238.3 289.69h-31.188a6.295 6.295 0 01-6.288-6.287v-29.176a6.295 6.295 0 016.288-6.288H238.3a6.295 6.295 0 016.288 6.288v29.176a6.295 6.295 0 01-6.288 6.288z"
      ></path>
    </svg>
  );
}

export default Tendency;
