import Tendency from "../../util/SVG/Icons/tendency";
import Social from "../../util/SVG/Icons/social";
import Strategy from "../../util/SVG/Icons/strategy";
const cardsData = [
  {
    icon: <Strategy />,
    title: "Sustainable Lab",
    text: "Exploramos as tendências sobre os novos padrões de comportamento e as mudanças socioambientais por setor de atividade. Assim, ajudamos as positive brands a anteciparem o futuro, destacarem-se, e ajustarem a sua estratégia ao contexto atual.",
  },
  {
    icon: <Tendency />,
    title: "Activation Lab",
    text: "Oferecemos projetos de consultoria para reforçar o purpose das positive brands, e assim estruturar um plano de ação para o traduzir no quotidiano da sua atividade. Neste sentido, integramos a sustentabilidade no posicionamento das marcas, mas também na sua forma de pensar e atuar estabelecendo a sua identidade relativa à sustentabilidade.",
  },
  {
    icon: <Social />,
    title: "Creative Lab",
    text: "Amplificamos o impacto e a visibilidade das positive brands com os seus públicos-alvo através de campanhas integradas, conteúdos envolventes e conceitos criativos. Assim, tornamos as marcas atores e influenciadores do seu próprio claim de sustentabilidade, criando dinâmicas eficazes e participativas.",
  },
];

export default cardsData;
