import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";
import DarkTheme from "./containers/darkTheme/darktheme";
import { BrowserRouter as Router} from 'react-router-dom';
//-------------------
// Import Pages
//-------------------
import App from "./App";

// --------------------
// Import utils
// --------------------
import GlobalStyles from "./util/Theme/GlobalStyles";
import { theme } from "./util/Theme";


ReactDOM.render(
  <React.StrictMode>
      <DarkTheme>
          <ThemeProvider theme={theme}>
        <GlobalStyles />
            <App />
          </ThemeProvider>
        </DarkTheme>
    </React.StrictMode>,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();