import PropTypes from "prop-types";

import Wrapper from "./Wrapper";

const P = (props) => {
  return <Wrapper {...props}>{props.children}</Wrapper>;
};

P.defaultProps = {};

P.propTypes = {
  fontSize: PropTypes.string,
};

export default P;
