//Import Npm Packages
import React, { useState, useEffect } from "react";

//Import CSS
import "./video.css";

//Import Components
import Container from "../../components/Container";
import Heading from "../../components/Heading";
import A from "../../components/A";
import P from "../../components/P";
import SVG from "../../components/SVG";
import CloseIcon from "../../util/SVG/Icons/closeicon";
import PlayIcon from "../../util/SVG/Icons/playicon";
import { Oval } from "react-loader-spinner";

const VideoModalBox = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [videoId, setVideoId] = useState();
  const APIkey = process.env.REACT_APP_GMAPS_API_KEY;
  const youtubeURL = `https://www.googleapis.com/youtube/v3/videos?id=3G0estZMkfU&key=${APIkey}`;

  useEffect(async () => {
    await fetch(youtubeURL)
      .then((res) => res.json())
      .then((data) => {
        setVideoId(data.items[0].id);
      });
    setIsLoading(false);
  });

  const [modalBoxOpen, setModalBoxOpen] = useState([false, "none"]);

  const toggleModalFunc = () => {
    let modalBoxFirstElement;
    let modalBoxSecondElement;
    modalBoxOpen.forEach((element, index) => {
      if (index === 0) {
        modalBoxFirstElement = !element;
      } else {
        if (element === "none") {
          modalBoxSecondElement = "video";
        } else if (element === "video") {
          modalBoxSecondElement = "none";
        }
        setModalBoxOpen([modalBoxFirstElement, modalBoxSecondElement]);
        modalBoxFirstElement === true
          ? (document.body.style.overflowY = "hidden")
          : (document.body.style.overflowY = "inherit");
      }
    });
  };

  return (
    <Container id="video_section" padding="6rem 0" flex justifyContent="center">
      {modalBoxOpen[0] === true && modalBoxOpen[1] === "video" ? (
        <Container
          backgroundColor="black"
          padding="50px 0 0 "
          height="100vh"
          width="100%"
          position="fixed"
          top="0"
          zIndex="99999"
          flex
          justifyContent="center"
          alignItems="center"
        >
          <SVG
            onClick={toggleModalFunc}
            width="24"
            height="24"
            svg={<CloseIcon />}
            position="absolute"
            right="15%"
            top="10%"
            cursor="pointer"
          />
          {isLoading ? (
            <Oval heigth="100" width="100" color="white" ariaLabel="loading" />
          ) : (
            <iframe
              title={videoId}
              width="670"
              height="520"
              frameborder="0"
              src={`https://www.youtube.com/embed/${videoId}`}
            />
          )}
        </Container>
      ) : null}
      <Container
        height="100%"
        maxWidth="1440px"
        tabletPadding="3rem 5rem"
        flex
        laptopFlexDirection="row"
        flexDirection="column-reverse"
        justifyContent="space-between"
        alignItems="center"
      >
        <Container
          margin="2rem 0 0 0"
          laptopLHeight="400px"
          laptopHeight="350px"
          height="300px"
          laptopWidth="50%"
          width="80%"
          flex
          justifyContent="center"
        >
          <Container
            height="auto"
            width="100%"
            backgroundImage="/video/videoimage.jpg"
            backgroundSize="cover"
            borderRadius="1.5%"
            position="relative"
          >
            <Container
              display="flex"
              tabletDisplay="none"
              position="absolute"
              top="calc(50% - 50px)"
              right="calc(50% - 50px)"
            >
              <A
                onClick={toggleModalFunc}
                height="100px"
                width="50%"
                target="_blank"
                cursor="pointer"
              >
                <Container
                  className="video-play-button"
                  height="100px"
                  width="100px"
                  flex
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="50%"
                >
                  <SVG width="20" height="20" svg={<PlayIcon />}></SVG>
                </Container>
              </A>
            </Container>
            <Container
              display="none"
              tabletDisplay="flex"
              alignItems="center"
              backgroundColor="#0fd24f"
              desktopWidth="50%"
              laptopLWidth="55%"
              laptopWidth="80%"
              tabletWidth="70%"
              right="0"
              bottom="3rem"
              padding="25px"
              borderRadius="75px 0 0 75px"
              position="absolute"
            >
              <A
                onClick={toggleModalFunc}
                height="100px"
                width="50%"
                target="_blank"
                cursor="pointer"
              >
                <Container
                  className="video-play-button"
                  height="100px"
                  width="100px"
                  flex
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="50%"
                >
                  <SVG width="20" height="20" svg={<PlayIcon />}></SVG>
                </Container>
              </A>
              <Heading
                level="1.2"
                textColor="#fff"
                fontWeight="800"
                margin="0 0 0 1rem"
              >
                Ver mais
              </Heading>
            </Container>
          </Container>
        </Container>
        <Container laptopWidth="45%" width="80%">
          <Heading textColor="#0fd24f" fontWeight="600">
            QUEM SOMOS?
          </Heading>
          <Heading
            width="fit-content"
            position="relative"
            className="underline"
            level="0.7"
            fontWeight="600"
          >
            Apoiamos organizações
          </Heading>
          <Heading
            width="fit-content"
            position="relative"
            className="underline"
            level="0.7"
            fontWeight="600"
          >
            públicas e privadas
          </Heading>
          <Heading
            width="fit-content"
            position="relative"
            className="underline"
            level="0.7"
            fontWeight="600"
            fontStyle="italic"
          >
            in making the change
          </Heading>
          <P>
            Somos uma consultora criativa com foco em acelerar o processo de
            transformação de marcas e negócios através da sustentabilidade.
          </P>
          <P>
            A nossa crença essencial baseia-se no facto de acreditarmos que as
            marcas que vão sobreviver e destacar-se no futuro próximo serão
            marcas responsáveis e sustentáveis.
          </P>
          <P>
            A nossa ambição é inspirar e oferecer soluções inovadoras e
            multistakeholders, alinhadas com as necessidades estratégicas dos
            nossos parceiros e clientes, que construam marcas-símbolo de mudança
            positiva e forças transformadoras para o progresso com propósito.
          </P>
        </Container>
      </Container>
    </Container>
  );
};

export default VideoModalBox;
