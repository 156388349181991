import React, { useEffect } from "react";

import Container from "../../components/Container";
import Heading from "../../components/Heading";
import P from "../../components/P";
import A from "../../components/A";
import Email from "../../sections/email";

import mapStyles from "./mapStyles";

const Contacto = () => {
  const APIkey = process.env.REACT_APP_GMAPS_API_KEY;
  useEffect(() => {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${APIkey}`;
    googleMapScript.async = true;
    window.document.head.appendChild(googleMapScript);

    googleMapScript.addEventListener("load", () => {
      initMap();
    });
  });

  const initMap = () => {
    let locator = {};
    // Initialize Map
    locator.map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: 38.7521674, lng: -9.1348 },
      scrollwheel: false,
      styles: mapStyles,
      zoom: 14,
    });

    let map = locator.map;

    new window.google.maps.Marker({
      position: { lat: 38.7521674, lng: -9.1348 },
      map,
    });
  };

  return (
    <Container
      padding="250px 0 0"
      flex
      flexDirection="column"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Container
        laptopLWidth="70%"
        laptopWidth="80%"
        tabletWidth="85%"
        width="95%"
        flex
        flexDirection="column"
      >
        <Heading className="contacto-heading" level="0.6" fontWeight="800">
          Contacte-nos
        </Heading>
        <Container
          width="100%"
          flex
          flexDirection="column-reverse"
          laptopFlexDirection="row"
          justifyContent="space-between"
        >
          <Container
            width="100%"
            laptopWidth="45%"
            alignItems="center"
            laptopAlignItems="flex-start"
            flex
            flexDirection="column"
            justifyContent="center"
          >
            <h2>Escritório</h2>
            <P>
              Rua Dom Pedro Cristo Nº1 A<br></br>1700-135 Lisboa, Portugal
            </P>
            <P>
              Tel:{" "}
              <A href="tel:00351213170626" blank color="inherit">
                (+351) 213 170 626
              </A>
            </P>
            <P>
              E-mail:{" "}
              <A
                href="mailto:geral@sustainablesociety.global"
                blank
                color="inherit"
              >
                geral@sustainablesociety.global
              </A>
            </P>
          </Container>
          <Container
            id="map"
            width="100%"
            laptopWidth="45%"
            height="400px"
            margin="3rem 0"
          ></Container>
        </Container>

        <Container
            width="100%"
            flex
            flexDirection="column-reverse"
            laptopFlexDirection="row"
            justifyContent="space-between"
        >
          <Container
              width="100%"
              laptopWidth="45%"
              alignItems="center"
              laptopAlignItems="flex-start"
              flex
              flexDirection="column"
              justifyContent="center"
          >
            <h2>Sede</h2>
            <P>
              Rua de Pedrouços, Nº 105 A<br></br>1400-288 Lisboa, Portugal
            </P>
          </Container>
        </Container>
        <Email></Email>
      </Container>
    </Container>
  );
};

export default Contacto;
