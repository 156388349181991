import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

//-------------------
// Import Components
//-------------------
import Home from "./pages/Home";
import Manifesto from "./pages/Manifesto";
import Solucoes from "./pages/Soluções";
import Contacto from "./pages/Contacto";
import PortofolioItem from "./pages/portfolio";
//-------------------
// Import Containers
//-------------------
import Navbar from "./containers/Navbar";
import Footer from "./containers/Footer/index";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/manifesto" element={<Manifesto />} />
        <Route exact path="/solucoes" element={<Solucoes />} />
        <Route exact path="/comunidade" />
        <Route exact path="/solucoes/:item" element={<PortofolioItem />} />
        <Route exact path="/contacto" element={<Contacto />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
