import styled from "styled-components";

import { device } from "../../util/Theme";

const Wrapper = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  z-index: ${({ zIndex }) => zIndex};
  cursor: ${({ cursor}) => cursor};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  display: ${({ flex }) => (flex ? "flex" : "")};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  background: ${({ background }) => background};
  border-radius: ${({ borderRadius }) => borderRadius};

  @media ${device.tablet} {
  top: ${({ tabletTop }) => tabletTop};
  left: ${({ tabletLeft }) => tabletLeft};
  right: ${({ tabletRight }) => tabletRight};
  bottom: ${({ tabletBottom }) => tabletBottom};
  }


  @media ${device.laptop} {
    width: ${({ laptopWidth }) => laptopWidth * 1.25}px;
    height: ${({ laptopHeight }) => laptopHeight * 1.25}px;
  }

  & svg {
    fill: ${({ fill }) => fill};
    stroke: ${({ stroke }) => stroke};
    stroke-width: ${({ strokeWidth }) => strokeWidth};
    width: ${({ width }) => width}px;
    padding: ${({ padding }) => padding};
  }
`;

export default Wrapper;
