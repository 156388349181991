//Import React Components
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import "./navbar.css";

// Import Utils
import { Squash as Hamburger } from "hamburger-react";
import Sun from "../../util/SVG/Icons/sun";
import Moon from "../../util/SVG/Icons/moon";
import Help from "../../util/SVG/Icons/help";

//Import Data
import menuItems from "./menuitems";

//Import Component
import Container from "../../components/Container";
import SVG from "../../components/SVG";
import Heading from "../../components/Heading";
import P from "../../components/P";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [darkTheme, setDarkTheme] = useState("white");
  const [navBackground, setNavBackground] = useState("white");

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen) {
      document.body.style.overflowY = "scroll";
    } else {
      document.body.style.overflowY = "hidden";
    }
  }

  function changeTheme() {
    var element = document.body;

    if (darkTheme === "dark") {
      setDarkTheme("white");
      setNavBackground("white");
      element.classList.toggle("light-mode");
    } else {
      setDarkTheme("dark");
      setNavBackground("black");
      element.classList.toggle("light-mode");
    }
  }

  return (
    <Container
      width="100%"
      position="fixed"
      backgroundColor={navBackground}
      zIndex="999"
      transition="0.3s background-color ease"
    >
      <Container
        padding="1rem"
        tabletPadding=".5rem 1.5rem"
        maxWidth="1400px"
        margin="0 auto"
      >
        <Container
          flex
          justifyContent="space-between"
          laptopJustifyContent="space-between"
          alignItems="center"
        >
          <Container
            flex
            alignItems="center"
            width="80%"
            tabletWidth="30%"
            laptopWidth="20%"
            justifyContent="flex-start"
            tabletJustifyContent="center"
          >
            <Link to="/" flex justifyContent="flex-start">
              <img
                style={{
                  margin: "0",
                  width: "auto",
                  height: "90px",
                }}
                src={
                  darkTheme == "dark"
                    ? "/logos/ssi_logowhite.png"
                    : "/logos/ssi_logodark.png"
                }
                alt="SSI Logo"
              />
            </Link>
          </Container>
          <Container
            display="none"
            laptopDisplay="flex"
            width="70%"
            flex
            justifyContent="flex-end"
            padding="1rem 0 0"
          >
            {menuItems.map((item, index) => {
              return (
                <Container key={index} textAlign="center" margin="0 3rem 0 0">
                  <Link
                    key={index}
                    className="navbar-links"
                    to={item.path}
                    style={{ cursor: "pointer" }}
                  >
                    {item.name.toUpperCase()}
                  </Link>
                </Container>
              );
            })}
          </Container>
          <Container
            width="50%"
            laptopWidth="10%"
            flex
            justifyContent="flex-end"
            alignItems="center"
            padding="1rem 0 0"
          >
            <Container>
              <label className="switch">
                <input type="checkbox" onClick={changeTheme} />
                <Container
                  flex
                  justifyContent="space-around"
                  className="slider round"
                >
                  <Container flex alignItems="center" height="100%">
                    <SVG flex stroke="#0fd24f" svg={<Moon />} />
                  </Container>
                  <Container flex alignItems="center" height="100%">
                    <SVG flex stroke="#0fd24f" svg={<Sun />} />
                  </Container>
                </Container>
              </label>
            </Container>
            <Container className="img__wrap" margin="0 1rem" display="none" tabletDisplay="block">
              <SVG flex alignItems="img__img" stroke="grey" svg={<Help />} />
              <Container
                className="img__description"
                backgroundColor={
                  document.body.classList.contains("light-mode")
                    ? "white"
                    : "black"
                }
                padding="0.5rem 1rem"
                borderRadius="25px"
                zIndex="99999"
              >
                <Heading level="2.5" fontWeight="600">
                  Porquê o modo escuro?
                </Heading>
                <P fontSize="12px">
                  Os monitores OLED implementam o modo escuro ao simplesmente
                  desligar alguns OLED(s) específicos, o que ajuda a poupar
                  eletricidade.
                </P>
              </Container>
            </Container>
          </Container>
          <Container
            display="block"
            zIndex="3002"
            laptopDisplay="none"
            padding="1rem 0 0"
          >
            <Hamburger
              toggled={isMenuOpen}
              toggle={toggleMenu}
              color={navBackground === "white" ? "black" : "white"}
            />
          </Container>
        </Container>
      </Container>
      <Container
        right={isMenuOpen ? "0" : "-160%"}
        height="100vh"
        width="100vw"
        backgroundColor={navBackground}
        padding="1rem 0"
        position="absolute"
        top="0"
        laptopDisplay="none"
        transition="all 1s ease 0s"
        zIndex="999"
        flex
        alignItems="center"
      >
        <Container
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          alignItems="center"
          height="50%"
          width="100%"
        >
          {menuItems.map((item, index) => {
            return (
              <Link
                onClick={isMenuOpen}
                key={index}
                className="navbar-links"
                to={item.path}
                style={{ cursor: "pointer" }}
              >
                {item.name.toUpperCase()}
              </Link>
            );
          })}
        </Container>
      </Container>
      <span
        style={{
          borderTop: "3px solid transparent",
          borderColor: "#0fd24f",
          borderImageSlice: "1",
          display: "block",
          position: "relative",
          width: "100%",
        }}
      ></span>
    </Container>
  );
};

export default Navbar;
