import { Suspense, useRef, useState, useEffect } from "react";
import "./Earth.css";

import { HalfBgButton } from "@ahpoise/react-components-library";
// import earthItems from './EarthItems';

//*Components
import { Canvas, useFrame } from "react-three-fiber";
import { useGLTF, Html } from "@react-three/drei";

import Container from "../../components/Container";
import Heading from "../../components/Heading";
import A from "../../components/A";

//*IMPORT OF MODELS
const EarthModel = () => {
  const gltf = useGLTF("/earth/scene.gltf", true);
  return <primitive object={gltf.scene} dispose={null} />;
};

const StarsModel = () => {
  const gltf = useGLTF("/stars/scene.gltf", true);
  return <primitive object={gltf.scene} dispose={null} />;
};

//*MAKING THE MODELS SPIN
const StarsSpin = () => {
  let ref = useRef();
  useFrame(() => {
    ref.current.rotation.y -= 0.0003;
  });
  return (
    <mesh ref={ref} scale={[0.5, 0.5, 0.5]} position={[0, 0, -1200]}>
      <StarsModel />
    </mesh>
  );
};

const EarthSpin = () => {
  let ref = useRef();

  const [widthScroll, setWidthScroll] = useState(0);
  const maxValue = Math.min(35, Math.max(0, widthScroll));

  // USE THIS VAR TO CHANGE PLANET ON SCROLL
  // let size = 40 - maxValue;
  // let positionY = -40 + maxValue;

  // function handleScroll() {
  //   let position = window.scrollY;
  //   let height = window.innerHeight;
  //   let bottom = document.body.scrollHeight;
  //   setWidthScroll((position * 100) / (bottom - height));
  // }

  // const hideText = () => {
  //   let section = document.querySelector('.text');
  //   let section2 = document.querySelector('.info');
  //     if (positionY === -5) {
  //       section.classList.add('hidden')
  //       section2.classList.add('show')
  //     } else {
  //       section.classList.remove('hidden')
  //       section2.classList.remove('show')
  //       section2.classList.add('hidden')
  //     }
  // }

  useEffect(() => {
    //window.addEventListener("scroll", handleScroll);
    //hideText();
  });

  useFrame(() => {
    ref.current.rotation.y += 0.001;
  });

  // Right Side position position={[100, -20 , 0]}
  return (
    <mesh ref={ref} scale={50} position={[30, -50, 150]}>
      <EarthModel />
    </mesh>
  );
};

const Light = () => {
  return (
    <>
      <ambientLight intensity={0.1} />
      <directionalLight position={[15, 10, 5]} intensity={1} />
    </>
  );
};

function Earth() {
  function SmoothScroll(id) {
    let sectionPosition = document.getElementById(id).offsetTop;
    let sectionPositionWithoutNavHeight = sectionPosition - 140;
    window.scrollTo({
      top: sectionPositionWithoutNavHeight,
      behavior: "smooth",
    });
  }

  return (
    <Container className="Earth_wrapper">
      <Container className="text">
        <Container position="relative" className="text_wrapper">
          <Heading level="1" levelTablet="0.5" laptopLevel="0.8">
            Make a difference
            <br />
          </Heading>
          <Heading
            width="fit-content"
            className="underline"
            position="relative"
            level="2"
            levelTablet="1"
            laptopLevel="2"
            textColor=""
          >
            Estratégias criativas em sustentabilidade
          </Heading>
          {/* <Container
            position='relative'
            top='-18px'
            flex 
            margin='1rem 0'
          >
            <span className='divider' />
          </Container> */}

          <A
            textDecoration="none"
            onClick={() => SmoothScroll("video_section")}
          >
            <Container margin="3rem 0">
              <HalfBgButton
                fontWeight="500"
                label="SABER MAIS"
                width={230}
                iconFill="black"
                backgroundColorOne="#0fd24f"
              />
            </Container>
          </A>
        </Container>
      </Container>

      <Container
        width="100vw"
        height="50vh"
        tabletHeight="50vh"
        tabletWidth="100vw"
        laptopWidth="100vw"
        laptopHeight="100vh"
      >
        <Canvas
          className="canvas"
          colorManagement
          camera={{ position: [0, 0, 250], fov: 30 }}
          //to see full planet change to 50 fov
        >
          <Light />
          <Suspense fallback={null}>
            <EarthSpin />
            <StarsSpin />
          </Suspense>
        </Canvas>
      </Container>
    </Container>
  );
}

export default Earth;
