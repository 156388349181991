import React from "react";

function PlayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#0fd24f"
      stroke="#0fd24f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      className="feather feather-play"
      viewBox="0 0 24 24"
    >
      <path d="M5 3L19 12 5 21 5 3z"></path>
    </svg>
  );
}

export default PlayIcon;