import React from "react";

function Strategy() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 691.927 626.302"
    >
      <path
        fill="#a0616a"
        d="M484.463 117.826a9.097 9.097 0 0010.057 7.735 8.728 8.728 0 001.41-.307l18.96 25.45 16.3-5.077-29.743-32.056a9.072 9.072 0 00-9.502-5.69 8.682 8.682 0 00-7.516 9.709q.015.118.034.236z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M618.347 231.493s-70.798-33.041-88.706-54.145l-32.09-39.845 16.274-13.314 30.164 34.744c-.275 7.3 35.378 14.784 54.544 25.426z"
      ></path>
      <path
        fill="#a0616a"
        d="M635.752 610.051L621.866 610.05 615.26 556.486 635.755 556.487 635.752 610.051z"
      ></path>
      <path
        fill="#2f2e41"
        d="M636.242 624.537l-42.704-.002v-.54a16.622 16.622 0 0116.622-16.62l7.801-5.918 14.554 5.918h3.727z"
      ></path>
      <path
        fill="#a0616a"
        d="M602.646 597.799L589.123 600.953 570.519 550.292 590.478 545.636 602.646 597.799z"
      ></path>
      <path
        fill="#2f2e41"
        d="M606.415 611.795l-41.587 9.702-.123-.526a16.622 16.622 0 0112.41-19.963h.001l6.252-7.535 15.518 2.456 3.63-.847z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M467.107 297.272H80.576a31.925 31.925 0 01-7.237-.917 32.31 32.31 0 01-24.761-31.512V133.462a32.45 32.45 0 0132.429-32.4h386.1a32.01 32.01 0 0114.944 3.658 31.058 31.058 0 015.182 3.381 32.07 32.07 0 0112.303 25.36v131.382a32.465 32.465 0 01-32.429 32.43z"
      ></path>
      <path
        fill="#0fd24f"
        d="M441.199 294.4q26.325-63.981 55.465-126.7v70.89c-13.472 20.107-28.408 38.978-45.728 55.81zM485.433 110.34q-52.09 93.452-109.006 184.06h-6.606q57.863-92.03 110.901-187.133a28.233 28.233 0 014.71 3.073zM230.598 178.357A1171.096 1171.096 0 01155.601 294.4h-39.38q38.691-47.135 77.439-94.27c7.41-9.048 15.453-18.527 26.57-22.204z"
      ></path>
      <path
        fill="#fff"
        d="M178.264 171.923a199.16 199.16 0 00-11.748 13.357c-8.157 9.823-16.2 19.733-24.271 29.613a4219.25 4219.25 0 00-15.023 18.44 36.683 36.683 0 01-4.48-3.388 40.192 40.192 0 0151.357-61.813 37.046 37.046 0 014.165 3.791z"
      ></path>
      <path
        fill="#0fd24f"
        d="M400.383 199.842l-75.486-27.603-37.484-13.7c-12.207-4.453-24.386-9.537-36.996-12.754a58.324 58.324 0 00-33.491.43 77.001 77.001 0 00-26.11 16.2q-4.998 4.61-9.68 9.508a199.16 199.16 0 00-11.748 13.357c-8.157 9.823-16.2 19.733-24.27 29.614a4219.254 4219.254 0 00-15.023 18.44q-24.903 30.461-49.52 61.066a28.786 28.786 0 01-6.548-.833q25.678-31.883 51.587-63.622c1.982-2.47 4.021-4.97 6.032-7.44q12.624-15.467 25.276-30.906c6.52-7.957 12.984-15.97 20.05-23.467.631-.69 1.263-1.35 1.924-2.04 14.333-14.821 32.228-27.919 53.655-28.264 12.61-.2 24.789 3.792 36.508 8.014 12.61 4.567 25.19 9.22 37.8 13.845l76.002 27.804 19.015 6.951c3.36 1.207 1.925 6.664-1.493 5.4z"
      ></path>
      <path
        fill="#3f3d56"
        d="M2 148.167V69.292a9.296 9.296 0 019.29-9.28H113.5v-2H11.29A11.298 11.298 0 000 69.292v78.875zM9.095 338.06A9.257 9.257 0 012 329.033v-78.865H0v78.865a11.248 11.248 0 008.62 10.971 11.115 11.115 0 002.52.32H113.5v-2H11.152a9.133 9.133 0 01-2.057-.262zM467 250.167v78.865a9.3 9.3 0 01-9.29 9.29H355.5v2h102.21a11.303 11.303 0 0011.29-11.29v-78.865zM464.991 61.06a8.86 8.86 0 011.481.968A9.185 9.185 0 01470 69.292v78.875h2V69.292a11.165 11.165 0 00-4.283-8.829 10.813 10.813 0 00-1.804-1.177 11.144 11.144 0 00-5.203-1.274H358.5v2h102.21a9.169 9.169 0 014.281 1.049z"
      ></path>
      <path
        fill="#2f2e41"
        d="M647.785 349.972l-86.402 4.33-6 29v25.831l-3.682 96.178 25.682 74.992h20.59l-2.09-9.5a9.913 9.913 0 00.341-11.024l-1.652-2.64-2.689-11.336-6-42 25.697-94.67 3.303 172.67h22.37l3.28-3.747a12.57 12.57 0 00.992-15.267v-4.187l-2.642-14.8 24.653-183.708z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M610.453 169.946l-28.185 1.443-3.202 13.359-20.467 15.664a31.248 31.248 0 00-11.67 30.842l11.345 57.716-.738 36.124 1.637 14.793-.124 2.24-2.506 2.05a9.7 9.7 0 00-2.527 11.856s-12.35 26.703 5.52 28.061 28.925-17.224 46-10 32.319 8.071 37 0 18.922-6.19 20.635-13.24-6.958-15.032-8.123-25.542-4.165-59.51-4.165-59.51l8.39-48.106a31.868 31.868 0 00-17.444-34.128l-26.86-13.077z"
      ></path>
      <path
        fill="#a0616a"
        d="M463.668 320.567a10.35 10.35 0 0113.484-5.155 9.93 9.93 0 011.437.794l28.961-21.566 16.114 10.846-42.868 25.253a10.322 10.322 0 01-12.212 3.107 9.878 9.878 0 01-5.03-13.033q.056-.124.114-.246z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M553.652 206.835s-37.542 68.358-52.08 88.464l-11.036 8.62 1 28.175 24.662-13.609s49.765-37.696 48.338-53.39-10.884-58.26-10.884-58.26z"
      ></path>
      <circle cx="593.969" cy="141.057" r="26.632" fill="#a0616a"></circle>
      <path
        fill="#2f2e41"
        d="M604.26 165.506c-7.643-.25-8.337-2.27-15.98-2.52-2.433-.08-5.304-.413-6.48-2.545-.839-1.518-.453-3.43.284-5a45.656 45.656 0 002.354-4.63 8.089 8.089 0 00-8.014-10.665c-2.326.1-4.51 1.194-6.83 1.394-3.18.274-10.213-10.228-8.749-13.064 1.426-2.762 4.294-4.845 4.745-7.92.295-2.01-.535-4.009-.649-6.038-.168-3.01 7.46 3.775 6.063 1.105-3.427-6.55 28.863-14.671 34.179-6.466 1.987 3.066 5.528 2.857 8.683 4.699 2.069 1.207 7.292 5.371 8.48 7.967a20.208 20.208 0 011.512 8.361c.047 13.314-1.981 25.561-12.72 33.431"
      ></path>
      <path
        fill="#f2f2f2"
        d="M471.489 618.404a48.675 48.675 0 01-39.26-14.645c-6.59-6.948-11.113-15.892-15.487-24.542-1.273-2.516-2.589-5.12-3.928-7.614a15.534 15.534 0 011.612-17.332 15.272 15.272 0 0116.303-5.208l.638.184 5.744 22.414c.857-3.343 2.697-10.876 3.858-18.256l.098-.618.572.255a78.132 78.132 0 0116.786 10.115 80.529 80.529 0 0130.112 60.643l.015.697z"
      ></path>
      <path
        fill="#cacaca"
        d="M690.736 625.995l-278.75.307a1.19 1.19 0 010-2.381l278.75-.308a1.19 1.19 0 110 2.382z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M159.453 422.58a26.164 26.164 0 1126.163-26.163 26.164 26.164 0 01-26.163 26.164z"
      ></path>
      <path
        fill="#fff"
        d="M169.776 393.836h-7.742v-7.743a2.58 2.58 0 00-5.162 0v7.743h-7.743a2.58 2.58 0 000 5.162h7.743v7.743a2.58 2.58 0 005.162 0v-7.743h7.742a2.58 2.58 0 100-5.162z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M236 422.58a26.164 26.164 0 1126.164-26.163A26.164 26.164 0 01236 422.581z"
      ></path>
      <path
        fill="#fff"
        d="M246.324 393.836h-20.648a2.58 2.58 0 000 5.162h20.648a2.58 2.58 0 100-5.162z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M312.547 422.58a26.164 26.164 0 1126.164-26.163 26.164 26.164 0 01-26.164 26.164z"
      ></path>
      <path
        fill="#fff"
        d="M327.267 395.174l-7.418-10.826a2.2 2.2 0 00-3.63 2.486l4.798 7.002h-18.793a2.58 2.58 0 100 5.162h19.029l-5.033 7.346a2.2 2.2 0 003.63 2.487l7.417-10.827a2.903 2.903 0 000-2.83z"
      ></path>
      <circle cx="236" cy="27.78" r="27.78" fill="#0fd24f"></circle>
      <path
        fill="#fff"
        d="M232.21 42.238a3.09 3.09 0 01-2.472-1.236l-7.578-10.105a3.09 3.09 0 114.944-3.708l4.958 6.61 12.735-19.102a3.09 3.09 0 115.142 3.429l-15.157 22.736a3.091 3.091 0 01-2.486 1.375l-.086.001z"
      ></path>
    </svg>
  );
}

export default Strategy;