import styled from "styled-components";

import { device } from "../../util/Theme";

const Wrapper = styled.a`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  text-align: ${({ textAlign }) => textAlign};
  font-size: ${({ fontSize }) => fontSize};
  margin: ${({ margin }) => margin};
  text-decoration: ${({ textDecoration }) => textDecoration};
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ padding }) => padding};
  cursor: ${({ cursor }) => cursor};
  display: ${({ flex }) => (flex ? "flex" : "")};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  background-color: ${({ backgroundColor }) => backgroundColor};
  text-decoration: ${({ textDecoration }) => textDecoration};
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};

  @media ${device.laptop} {
    font-size: ${({ laptopFontSize }) => laptopFontSize};
  }

  @media ${device.laptopL} {
    font-size: ${({ laptopLFontSize }) => laptopLFontSize};
  }
`;

export default Wrapper;
