import React from "react";
import "./cards.css";

//import Components
import Container from "../../components/Container";
import Heading from "../../components/Heading";
import SVG from "../../components/SVG";
import P from "../../components/P";

function Cards(props) {
  return (
    <Container className="row_of_3">
      <Container
        width="100%"
        flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column "
        laptopFlexDirection="row"
        tabletFlexDirection="column"
        tabletAlignItems="center"
        laptopMargin=" 5rem 0 0 0"
      >
        {props.cardsData.map((item, key, arr) => {
          return (
            <Container
              key={key}
              flex
              padding="3rem 0"
              laptopPadding="1rem"
              tabletPadding="3rem 0"
              flexDirection="column"
              tabletFlexDirection="column"
              alignItems="center"
              width="90%"
              laptopminHeight="700px"
              laptopLminHeight="520px"
              desktopminHeight="200px"
              laptopWidth="33%"
              tabletWidth="50%"
              textAlign="center"
              tabletBorderBottom={
                arr.length - 1 === key ? "" : "1px solid grey"
              }
              tabletBorderRight="0"
              laptopBorderBottom="0"
              laptopBorderRight={arr.length - 1 === key ? "" : "1px solid grey"}
            >
              <Container textAlign="center" className="icon" margin="2rem 0">
                <SVG width="150" height="150" svg={item.icon} />
              </Container>
              <Container
                width="80%"
                flex
                width="fit-content"
                flexDirection="column"
                tabletAlignItems="center"
                padding="0 2rem"
              >
                <Container margin="1rem">
                  <Heading
                    position="relative"
                    className={props.underline}
                    level="1"
                    laptopLevel="1.5"
                    tabletLevel="1"
                  >
                    {item.title}
                  </Heading>
                </Container>
                <Container>
                  {" "}
                  {item.text ? (
                    <P
                      fontSize="16px"
                      textAlign="left"
                      tabletTextAlign="center"
                      laptopTextAlign="center"
                    >
                      {item.text}
                    </P>
                  ) : (
                    <ul
                      style={{
                        fontSize: "16px",
                        textAlign: "left",
                        lineHeight: "1.7",
                      }}
                    >
                      <li>{item.point1}</li>
                      <li>{item.point2}</li>
                      <li>{item.point3}</li>
                    </ul>
                  )}
                </Container>
              </Container>
            </Container>
          );
        })}
      </Container>
    </Container>
  );
}

export default Cards;
