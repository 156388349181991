import PropTypes from "prop-types";

import Wrapper from "./Wrapper";

const A = (props) => {
  return (
    <Wrapper href={props.href} target={props.blank ? "_blank" : ""} {...props}>
      {props.children}
    </Wrapper>
  );
};

A.defaultProps = {};

A.propTypes = {
  href: PropTypes.string,
  blank: PropTypes.bool,
  textDecoration: PropTypes.string,
};

export default A;
