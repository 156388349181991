import styled from "styled-components";

import { device, theme } from "../../util/Theme";

const Wrapper = styled.div`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
  height: ${({ height }) => height};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  display: ${({ flex }) => (flex ? "flex" : "")};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  font-family: ${({ fontFamily }) =>
    fontFamily === "secondary"
      ? theme.main.fonts.secondary
      : theme.main.fonts.primary};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  z-index: ${({ zIndex }) => zIndex};
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: ${({ backgroundPosition }) => backgroundPosition};
  background-size: ${({ backgroundSize }) => backgroundSize};
  background-repeat: ${({ backgroundRepeat }) => backgroundRepeat};
  border-radius: ${({ borderRadius }) => borderRadius};
  overflow: ${({ overflow }) => overflow};
  position: ${({ position }) => position};
  box-shadow: ${({ boxShadow }) => boxShadow};
  transition: ${({ transition }) => transition};
  writing-mode: ${({ writingMode }) => writingMode};
  transform: ${({ transform }) => transform};
  text-align: ${({ textAlign }) => textAlign};
  border: ${({ border }) => border};
  color: ${({ color }) => color};
  border-style: ${({ borderStyle }) => borderStyle};
  border-bottom: ${({ borderBottom }) => borderBottom};
  border-right: ${({ borderRight }) => borderRight};
  display: ${({ display }) => display};
  overflow-y: ${({ overflowY }) => overflowY};
  min-height: ${({ minHeight }) => minHeight};
  border-bottom-left-radius: ${({ borderBottomLeftRadius }) => borderBottomLeftRadius};
  border-top-right-radius: ${({ borderTopRightRadius }) => borderTopRightRadius};

  & .cards:hover  .corner{
    width:60px;
    height:60px;
    transition: all 500ms ease-in-out;
  }

 & .cards:hover > .icon_display{
    animation: beat 5s ease-in-out infinite;
  }

  @keyframes beat {
    0% {
      transform: scale(1);
    }
    35% {
      transform: scale(1.3);
    }
  }

  #hide {
    display: none;
  }

  &:hover + #hide {
    display: block;
    animation: fadeIn 1s;
    opacity: 1;
  }

  .faq_content {
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    transition: all ease-in-out 1s;
  }

  .faq_content > div {
    min-height: 60px;
  }

  .faq_content.active {
    transition: all ease-in-out 1s;
    height: 100%;
    max-height: 1000px;
  }

  .navbar-links {
    font-size: 16px;
    color: inherit;
    text-decoration: none;
  }

  @media ${device.tablet} {
    display: ${({ tabletDisplay }) => tabletDisplay};
    flex-direction: ${({ tabletFlexDirection }) => tabletFlexDirection};
    width: ${({ tabletWidth }) => tabletWidth};
    height: ${({ tabletHeight }) => tabletHeight};
    padding: ${({ tabletPadding }) => tabletPadding};
    justify-content: ${({ tabletJustifyContent }) => tabletJustifyContent};
    align-items: ${({ tabletAlignItems }) => tabletAlignItems};
    margin: ${({ tabletMargin }) => tabletMargin};
    overflow: ${({ tabletOverflow }) => tabletOverflow};
    flex-wrap: ${({ tabletFlexWrap }) => tabletFlexWrap};
    border-bottom: ${({ tabletBorderBottom }) => tabletBorderBottom};
    border-right: ${({ tabletBorderRight }) => tabletBorderRight};
    top: ${({ tabletTop }) => tabletTop};
    left: ${({ tabletLeft }) => tabletLeft};
    right: ${({ tabletRight }) => tabletRight};
    bottom: ${({ tabletBottom }) => tabletBottom};
  }

  @media ${device.laptop} {
    display: ${({ laptopDisplay }) => laptopDisplay};
    flex-direction: ${({ laptopFlexDirection }) => laptopFlexDirection};
    width: ${({ laptopWidth }) => laptopWidth};
    height: ${({ laptopHeight }) => laptopHeight};
    min-height: ${({ laptopminHeight }) => laptopminHeight};
    padding: ${({ laptopPadding }) => laptopPadding};
    margin: ${({ laptopMargin }) => laptopMargin};
    justify-content: ${({ laptopJustifyContent }) => laptopJustifyContent};
    align-items: ${({ laptopAlignItems}) => laptopAlignItems};
    overflow: ${({ laptopOverflow }) => laptopOverflow};
    display: ${({ laptopDisplay }) => laptopDisplay};
    transform: ${({ laptopTransform }) => laptopTransform};
    writing-mode: ${({ laptopWritingMode }) => laptopWritingMode};
    position: ${({ laptopPosition }) => laptopPosition};
    top: ${({ laptopTop }) => laptopTop};
    left: ${({ laptopLeft }) => laptopLeft};
    right: ${({ laptopRight }) => laptopRight};
    bottom: ${({ laptopBottom }) => laptopBottom};
    flex-wrap: ${({ laptopFlexWrap }) => laptopFlexWrap};
    text-align: ${({ laptopTextAlign }) => laptopTextAlign};
    border-bottom: ${({ laptopBorderBottom }) => laptopBorderBottom};
    border-right: ${({ laptopBorderRight }) => laptopBorderRight};
  }

  @media ${device.laptopL} {
    display: ${({ laptopLDisplay }) => laptopLDisplay};
    flex-direction: ${({ laptopLFlexDirection }) => laptopLFlexDirection};
    width: ${({ laptopLWidth }) => laptopLWidth};
    height: ${({ laptopLHeight }) => laptopLHeight};
    min-height: ${({ laptopLminHeight }) => laptopLminHeight};
    padding: ${({ laptopLPadding }) => laptopLPadding};
    position: ${({ laptopLPosition }) => laptopLPosition};
    margin: ${({ laptopLMargin }) => laptopLMargin};
    justify-content: ${({ laptopLJustifyContent }) => laptopLJustifyContent};
    overflow: ${({ laptopLOverflow }) => laptopLOverflow};
    display: ${({ laptopLDisplay }) => laptopLDisplay};
    transform: ${({ laptopLTransform }) => laptopLTransform};
    writing-mode: ${({ laptopLWritingMode }) => laptopLWritingMode};
    top: ${({ laptopLTop }) => laptopLTop};
    left: ${({ laptopLLeft }) => laptopLLeft};
    right: ${({ laptopLRight }) => laptopLRight};
    bottom: ${({ laptopLBottom }) => laptopLBottom};
    flex-wrap: ${({ laptopLFlexWrap }) => laptopLFlexWrap};
  }

  @media ${device.desktop} {
    width: ${({ desktopWidth }) => desktopWidth};
    margin: ${({ desktopMargin }) => desktopMargin};
    height: ${({ desktopHeight }) => desktopHeight};
    min-height: ${({ desktopminHeight }) => desktopminHeight};
    top: ${({ desktopTop }) => desktopTop};
    left: ${({ desktopLeft }) => desktopLeft};
    right: ${({ desktopRight }) => desktopRight};
    bottom: ${({ desktopBottom }) => desktopBottom};
  }
`;

export default Wrapper;
