const menuItems = [
  {
    name: "Início",
    path: "/",
  },
  {
    name: "Manifesto",
    path: "/manifesto",
  },
  {
    name: "Soluções",
    path: "/solucoes",
  },
  {
    name: "Contacto",
    path: "/contacto",
  },
];

export default menuItems;
