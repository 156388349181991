import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";

import Container from "../../components/Container";
import A from "../../components/A";
import Heading from "../../components/Heading";
import HalfBgButton from "@ahpoise/react-components-library/dist/components/HalfBgButton";

const PortofolioItem = (props) => {
  const location = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/portfolio`)
      .then((res) => {
        console.log(res);
        setData(res.data);
      })
      .catch((err) => console.log(err));

    window.addEventListener("scroll", () => {
      let scrolled = window.pageYOffset;
      let parallax = document.querySelector(".parallax-header");
      let coords = scrolled * 1 + "px";
      parallax.style.transform = "translateY(" + coords + ")";
    });
  }, []);
  return (
    <Container>
      {data.map((item, key) => {
        if (item.item_path === location.item) {
          return (
            <Container padding="100px 0 0">
              <Container className="parallax-wrapper">
                <Container
                  className="parallax-header"
                  backgroundImage={item.image}
                  width="100%"
                  height="400px"
                  backgroundSize="cover"
                  backgroundPosition="center"
                ></Container>
              </Container>
              <Container flex alignItems="center" justifyContent="center">
                <Container
                  laptopLWidth="70%"
                  laptopWidth="80%"
                  tabletWidth="85%"
                  width="95%"
                  flex
                  flexDirection="column"
                >
                  <Container margin="1% 0 0" key={key}>
                    <Container margin="2% 0 0">
                      <Link to="/solucoes" style={{ textDecoration: "none" }}>
                        <HalfBgButton
                          backgroundColorOne="#0fd24f"
                          label="Voltar"
                          width={200}
                          iconPosition="left"
                        />
                      </Link>
                    </Container>
                    <Heading margin="1% 0 0" level="0.6">
                      {item.title}
                    </Heading>
                    <Container
                      className="portfolio-description"
                      margin="2% 0 0"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></Container>
                    {item.website ? (
                      <Container
                        margin="5% 0 0"
                        width="100%"
                        flex
                        justifyContent="center"
                      >
                        <A
                          href={item.website}
                          style={{ textDecoration: "none", color: "inherit" }}
                          target="_blank"
                        >
                          <button className="portfolio-button">Ver mais</button>
                        </A>
                      </Container>
                    ) : null}
                  </Container>
                </Container>
              </Container>
            </Container>
          );
        }
      })}
    </Container>
  );
};

export default PortofolioItem;
