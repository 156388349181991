import PropTypes from "prop-types";

import Wrapper from "./Wrapper";

const SVG = (props) => {
  return <Wrapper {...props}>{props.svg}</Wrapper>;
};

SVG.defaultProps = {
  width: "",
  height: "",
  margin: "0",
  padding: "0",
};

SVG.propTypes = {
  background: PropTypes.string,
  borderRadius: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
  flex: PropTypes.bool,
  column: PropTypes.bool,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
};

export default SVG;
