import React from "react";

import cardsData from "./cardsData";
import displayData from "./displayData";

import Earth from "../../containers/Earth";
import CTA from "../../sections/CTA";
import Cards from "../../sections/cards";
import Display from "../../sections/display";
import Video from "../../sections/video";
import Container from "../../components/Container";

function Home() {
  return (
    <Container>
      <Earth />
      <CTA />
      <Video />
      <Cards cardsData={cardsData} underline="underline" />
      <Display displayData={displayData} />
    </Container>
  );
}
export default Home;
