import React from "react";

import Container from "../../components/Container";
import Heading from "../../components/Heading";
import P from "../../components/P";
import DefaultPage from "../../containers/DefaultPage";
import manifestoItems from "./manifestodata";

const Manifesto = () => {
  return (
    <Container>
      <Container
        padding="250px 0 0"
        flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <DefaultPage
          heading={manifestoItems.heading}
          paragraph={manifestoItems.paragraph}
        />
      </Container>
      <Container flex alignItems="center" justifyContent="center">
        <Container
          laptopLWidth="70%"
          laptopWidth="80%"
          tabletWidth="85%"
          width="95%"
          flex
          flexDirection="column"
        >
          <Container
            margin="3rem 0 6rem"
            flex
            alignItems="center"
            justifyContent="center"
          >
            <iframe
              width="660"
              height="415"
              src="https://www.youtube.com/embed/3G0estZMkfU"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Container>
          <Container>
            <Container>
              <Heading
                className="nossomanifesto-heading"
                level="0.7"
                fontWeight="800"
              >
                O Nosso Manifesto
              </Heading>
            </Container>
            <Container>
              <P fontSize="16px" fontWeight="600" margin="1.5rem 0">
                Num mundo em constante mudança, que inova repetidamente e
                procura cada vez mais responder às questões ambientais e de
                desigualdade social... Como pode uma organização encontrar a sua
                razão de ser? Como pode fazer a diferença e incentivar projetos
                com impacto positivo a nível nacional? Como pode antecipar o
                futuro de uma forma sustentável?
              </P>
            </Container>
            <Container
              flex
              flexDirection="column"
              justifyContent="space-between"
              tabletFlexDirection="row"
              margin="6rem 0 3rem"
            >
              <Container
                width="100%"
                tabletWidth="30%"
                margin="1.5rem 0"
                tabletMargin="0"
              >
                <Container flex justifyContent="center">
                  <img
                    src="/icons/world.png"
                    style={{ width: "80px", height: "80px" }}
                  />
                </Container>
                <P textAlign="center">
                  O impasse do nosso modelo de desenvolvimento – revelado pela
                  atual crise económica, social e política – está a provocar um
                  momento de rutura não só das trajetórias das sociedades, mas
                  também dos paradigmas que as sustentam.
                </P>
              </Container>
              <Container
                width="2.5%"
                flex
                flexDirection="column"
                alignItems="center"
              >
                <span className="manifesto-separator"></span>
              </Container>
              <Container
                width="100%"
                tabletWidth="30%"
                margin="1.5rem 0"
                tabletMargin="0"
              >
                <Container flex justifyContent="center">
                  <img
                    src="/icons/cogwheel.png"
                    style={{ width: "80px", height: "80px" }}
                  />
                </Container>
                <P textAlign="center">
                  Neste sentido, ajudamos as organizações – que desejam agir em
                  prol de uma mudança socioambiental positiva – a aumentar o seu
                  valor e reputação, além de fortalecer as suas relações com os
                  stakeholders, tornando a sustentabilidade uma verdadeira
                  alavanca de inspiração, de mudança e de transformação.
                </P>
              </Container>
              <Container
                width="2.5%"
                flex
                flexDirection="column"
                alignItems="center"
              >
                <span className="manifesto-separator"></span>
              </Container>
              <Container
                width="100%"
                tabletWidth="30%"
                margin="1.5rem 0"
                tabletMargin="0"
              >
                <Container flex justifyContent="center">
                  <img
                    src="/icons/light-bulb.png"
                    style={{ width: "80px", height: "80px" }}
                  />
                </Container>
                <P textAlign="center">
                  Para tal, oferecemos uma experiência de fusão entre estratégia
                  de sustentabilidade e comunicação criativa, que articula e
                  amplifica o impacto positivo destas organizações. Conjugamos
                  significado e ação, propósito e compromissos, estratégia de
                  marca e estratégia de sustentabilidade.
                </P>
              </Container>
            </Container>
          </Container>
          <Container margin="3rem 0 0">
            <Heading
              className="nossomanifesto-heading"
              level="0.7"
              fontWeight="800"
            >
              Os Nossos Compromissos
            </Heading>
            <Container margin="3rem 0 0">
              <Heading
                width="fit-content"
                position="relative"
                className="manifesto-underline"
                level="1"
                fontWeight="800"
              >
                Valorizamos o seu Capital
              </Heading>
              <Container>
                <P fontSize="16px" fontWeight="600">
                  Descobrimos e exploramos os novos padrões de comportamento e
                  as mudanças socioambientais para permitir aos nossos clientes
                  antecipar o futuro, destacar-se, ajustar a sua estratégia ao
                  contexto e aos eventos atuais.
                </P>
              </Container>
              <Container margin="3rem 0 0">
                <Heading
                  textAlign="right"
                  width="fit-content"
                  position="relative"
                  className="manifesto-underline"
                  level="0.9"
                  fontWeight="800"
                >
                  Reinventamos a sua Visão
                </Heading>
                <Container>
                  <P fontSize="16px" fontWeight="600">
                    Incentivamos os nossos clientes a integrar a
                    sustentabilidade na sua operação, estrutura, forma de
                    pensar, mas também no seu posicionamento e na sua
                    comunicação, estabelecendo a sua identidade e os seus
                    objetivos relativos à sustentabilidade.
                  </P>
                </Container>
              </Container>
            </Container>
            <Container margin="3rem 0 0">
              <Heading
                width="fit-content"
                position="relative"
                className="manifesto-underline"
                level="1"
                fontWeight="800"
              >
                Comunicamos com Impacto
              </Heading>
              <Container>
                <P fontSize="16px" fontWeight="600">
                  Impulsionamos os nossos clientes a partilhar a sua história de
                  Positive Brand com os seus públicos-alvo​ criando campanhas
                  integradas, conteúdos envolventes e conceitos criativos para
                  colocar em prática o plano de ação e integrá-lo na cultura da
                  empresa.
                </P>
              </Container>
            </Container>
            <Container margin="3rem 0 0">
              <Heading
                textAlign="right"
                width="fit-content"
                position="relative"
                className="manifesto-underline"
                level="1"
                fontWeight="800"
              >
                Envolvemos os seus Stakeholders
              </Heading>
              <Container>
                <P fontSize="16px" fontWeight="600">
                  Tornamos os nossos clientes atores e influenciadores do seu
                  próprio claim de sustentabilidade através de uma verdadeira
                  parceria com os seus stakeholders, que desenvolve e divulga o
                  conhecimento, além de criar dinâmicas eficazes e
                  participativas.
                </P>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default Manifesto;
