import React, {useState} from "react";
import "./Footer.css";

//-------------------
// Import Components
//-------------------
import Container from "../../components/Container";
import A from "../../components/A";
import P from "../../components/P";
import SVG from "../../components/SVG";

//-------------------
// Import SVG
//-------------------
import Linkedin from "../../util/SVG/Socials/linkedin";
import Instagram from "../../util/SVG/Socials/instagram";

function Footer() {
  const [darkTheme, setDarkTheme] = useState("white");

  return (
    <Container
      className="footer"
      height="150px"
      width="100%"
      flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      margin="5% 0 0"
    >
      <Container className="footer-social-icons" flex>
        <A
          target='_blank'
          className="linkedin"
          href="https://www.linkedin.com/company/sustainablesocietyinitiative/"
        >
          <SVG
            width="45"
            height="45"
            flex
            alignItems="center"
            justifyContent="center"
            svg={<Linkedin />}
          />
        </A>
        <A target='_blank' className="instagram" href="https://www.instagram.com/ssi_agencia/">
          <SVG
            width="45"
            height="45"
            flex
            alignItems="center"
            justifyContent="center"
            svg={<Instagram />}
          />
        </A>
      </Container>
      
      <P margin="1rem 0 0">© 2022. Sustainable Society Initiative.</P>

      <Container
            flex
            alignItems="center"
            width="80%"
            justifyContent="flex-start"
            tabletJustifyContent="center"
            style={{marginTop: "15px"}}
          >
            <span style={{fontWeight: "bold"}}>Uma empresa </span>
            <A href="https://gcimedia.pt/" flex justifyContent="flex-start" target="_blank">
              <img
                style={{
                  margin: "5px",
                  height: "auto",
                  width: "170px",
                }}
                src={
                  darkTheme == "dark"
                    ? "/logos/gcimedia-a.svg"
                    : "/logos/gcimedia-b.svg"
                }
                alt="SSI Logo"
              />
            </A>
          </Container>
    </Container>
  );
}

export default Footer;
