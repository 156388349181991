import React from "react";

function StrategySolutions() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 920.75 740.381"
    >
      <path
        fill="#f2f2f2"
        d="M823.25 362q0 9.975-.54 19.81a360.64 360.64 0 01-11.36 72.57c-.01.01-.01.01 0 .02-.82 3.14-1.69 6.27-2.61 9.37-.04.15-.08.29-.13.43q-2.04 6.975-4.36 13.82v.01a362.277 362.277 0 01-343 245.97c-171.23 0-314.68-118.87-352.34-278.6a362.657 362.657 0 01-9.66-83.4q0-8.37.38-16.65a360.161 360.161 0 0163.25-188.38c.46-.68.93-1.36 1.41-2.03C229.71 61.27 338.33 0 461.25 0 634.97 0 780.1 122.36 815.16 285.6a.031.031 0 01.01.02q3.39 15.78 5.38 32.04a362.557 362.557 0 012.7 44.34z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M917.515 665.621a96.901 96.901 0 01-12.51 27.53c-12.48 18.87-30.34 33.3-49.97 44.85-1.36.81-2.74 1.6-4.12 2.38h-13.77l-.48-1.1v-.01l-.56-1.27-.07-.15-.03.15-.55 2.38h-6.76c-.56-.79-1.11-1.58-1.65-2.38a78.74 78.74 0 01-2.27-3.55 57.634 57.634 0 01-3.4-6.52c-.12-.25-.22-.5-.32-.75l.45-.49-1.87-8.18 3.76-.62-4.12-12.71c.15-.47.32-.94.5-1.41 2.31-5.85 6.65-10.22 11.8-13.89 5.3-3.79 11.47-6.83 17.14-9.99 2.66-1.49 5.28-3.06 7.83-4.72v-.01q4.89-3.165 9.49-6.77a1.425 1.425 0 00.15-.11 1.068 1.068 0 00.12-.1 140.902 140.902 0 0034.31-38.93.01.01 0 00.01-.01c.3-.48.58-.96.86-1.45v-.01a136.255 136.255 0 009.41-19.65v-.01c.2-.5.38-1 .57-1.5q.39.66.75 1.32a.01.01 0 01.01.01c9.7 17.35 10.62 38.25 5.29 57.67z"
      ></path>
      <path
        fill="#fff"
        d="M912.225 607.951a121.365 121.365 0 01-2.87 27.54 118.54 118.54 0 01-3.36 12.24 121.345 121.345 0 01-10.13 22.6c-.23.39-.45.77-.69 1.15v.01a102.347 102.347 0 01-5.75 8.66 47.722 47.722 0 01-11.4 11.46 30.089 30.089 0 01-11.98 4.63c-.8.15-1.62.26-2.45.36-3.31.39-6.73.54-10.01 1.24a21.848 21.848 0 00-4.82 1.55c-5.28 2.45-8.25 7.58-9.55 13.06-.23.96-.41 1.93-.55 2.9-1.09 7.45.11 15.19-.51 22.65-.07.79-.16 1.59-.27 2.38h-.74l-.48-1.1v-.01c.05-.42.09-.84.13-1.27a96.295 96.295 0 00.09-11.01c-.04-1.65-.07-3.31-.05-4.97.02-1.33.07-2.66.17-3.98v-.01c.07-.82.15-1.63.26-2.44a31.586 31.586 0 011.82-7.25 18.152 18.152 0 0110.32-10.68 26.993 26.993 0 014.47-1.25c3.35-.64 6.81-.8 10.18-1.22.36-.04.72-.09 1.07-.15.45-.07.89-.15 1.33-.23a28.666 28.666 0 0012.16-5.27 51.903 51.903 0 0010.98-11.92c1.64-2.35 3.2-4.75 4.65-7.22a.01.01 0 01.01-.01c.07-.12.14-.23.21-.35l.01-.02a119.571 119.571 0 0013.46-34.17c.09-.44.19-.88.29-1.32a120.888 120.888 0 002.67-26.4v-.01c0-.42-.01-.83-.02-1.25-.02-.86 1.31-1.05 1.33-.19.01.42.02.84.01 1.26a.01.01 0 01.01.01z"
      ></path>
      <path
        fill="#fff"
        d="M894.605 671.21a18.018 18.018 0 0022.694-5.415c.509-.7-.554-1.523-1.064-.823a16.684 16.684 0 01-21.12 4.995c-.77-.395-1.275.85-.51 1.243zM866.584 695.21a34.729 34.729 0 011.23-25.28c.354-.789-.89-1.298-1.244-.51a36.123 36.123 0 00-1.226 26.309c.278.82 1.517.297 1.24-.52zM908.612 634.654a10.2 10.2 0 01-7.022-6.66c-.268-.823-1.507-.299-1.24.52a11.429 11.429 0 007.752 7.384.695.695 0 00.877-.367.675.675 0 00-.367-.877z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M856.546 675.161v.01a131.247 131.247 0 01-2.67 21.24v.01c-.08.48-.19.95-.29 1.42a184.496 184.496 0 01-10.11 31.61q-1.785 4.32-3.74 8.55c-.36.8-.73 1.59-1.11 2.38h-1.48l-.48-1.1v-.01l-.56-1.27-.07-.15-.03.15-.55 2.38h-14.3l-2.78-2.38-4.05-3.47 5.66-6.18.93-1.02.14-.15.45-.49-1.87-8.18 3.76-.62-4.12-12.71-1.95-6.01-.49-1.51-2.85-8.79-8.6 1.61-1.4-4.9a135.99 135.99 0 006.42-18.41c.01-.05.03-.09.04-.14a141.583 141.583 0 004.02-51.96v-.01c-.06-.56-.13-1.13-.21-1.68v-.02a133.631 133.631 0 00-4.32-21.34c-.14-.53-.3-1.05-.45-1.57.46.2.91.39 1.37.59l.01.01h.02c.73.32 1.46.66 2.17 1.02 17.06 8.29 29.61 23.85 36.74 41.79.02.05.04.09.06.14a96.394 96.394 0 016.57 29.43 114.87 114.87 0 01.12 11.73z"
      ></path>
      <path
        fill="#fff"
        d="M830.596 740.381h-1.58c-.5-.81-1.04-1.6-1.58-2.38q-1.29-1.814-2.66-3.55c-.79-1-1.58-1.99-2.37-2.98-.82-1.03-1.63-2.06-2.42-3.12l.93-1.02c.15.2.3.4.46.6 2.56 3.37 5.33 6.61 7.7 10.07.53.78 1.04 1.57 1.52 2.38zM809.154 591.01c.26.33.53.67.78 1.01q2.925 3.795 5.53 7.83a117.99 117.99 0 018.22 14.85c.19.4.38.81.56 1.22a119.264 119.264 0 019.84 35.44c.02.12.03.23.04.35q.495 4.29.64 8.61a51.783 51.783 0 01-1.59 16.12c-.2.69-.42 1.37-.66 2.03a27.345 27.345 0 01-2.25 4.65 27.923 27.923 0 01-2.14 3.09c-.47.6-.96 1.19-1.48 1.76-.3.33-.61.66-.92.99-.26.25-.51.51-.77.76-2.7 2.62-5.68 5.05-8.12 7.94l.49 1.51c2.35-2.98 5.38-5.45 8.11-8.05a37.896 37.896 0 004.66-5.28c.18-.26.37-.52.54-.79a26.749 26.749 0 002.39-4.29c.34-.75.65-1.52.93-2.32a47.893 47.893 0 002.2-16 103.418 103.418 0 00-.62-10.39c-.04-.45-.09-.89-.15-1.34a121.638 121.638 0 00-5.52-24.14 122.66 122.66 0 00-13.76-28.18q-2.475-3.765-5.25-7.35c-.24-.33-.49-.65-.75-.98-.53-.68-1.48.27-.95.95z"
      ></path>
      <path
        fill="#fff"
        d="M834.908 652.177a18.018 18.018 0 0014.859-17.988.673.673 0 00-1.344-.016 16.684 16.684 0 01-13.857 16.704c-.852.147-.506 1.446.342 1.3zM826.984 688.21a34.728 34.728 0 01-14.238-20.925c-.192-.844-1.492-.502-1.3.341a36.123 36.123 0 0014.86 21.744c.717.487 1.39-.676.678-1.16zM824.082 614.555a10.2 10.2 0 01-9.616-1.089c-.71-.496-1.383.668-.678 1.16a11.429 11.429 0 0010.636 1.23.695.695 0 00.479-.822.675.675 0 00-.82-.479z"
      ></path>
      <path
        fill="#cacaca"
        d="M633.317 156.974H.993a1.016 1.016 0 010-2.03h632.324a1.016 1.016 0 010 2.03z"
      ></path>
      <ellipse
        cx="23.348"
        cy="131.591"
        fill="#3f3d56"
        rx="10.925"
        ry="11.169"
      ></ellipse>
      <ellipse
        cx="61.09"
        cy="131.591"
        fill="#3f3d56"
        rx="10.925"
        ry="11.169"
      ></ellipse>
      <ellipse
        cx="98.832"
        cy="131.591"
        fill="#3f3d56"
        rx="10.925"
        ry="11.169"
      ></ellipse>
      <path
        fill="#3f3d56"
        d="M610.7 123.19h-26.81a2.03 2.03 0 000 4.06h26.81a2.03 2.03 0 000-4.06zM610.7 130.81h-26.81a2.03 2.03 0 000 4.06h26.81a2.03 2.03 0 000-4.06zM610.7 138.42h-26.81a2.03 2.03 0 000 4.06h26.81a2.03 2.03 0 000-4.06z"
      ></path>
      <path
        fill="#ccc"
        d="M166.66 198.35h-28.39a359.322 359.322 0 00-33.45 100.05h61.84a14.974 14.974 0 0015-15v-70.06a15.01 15.01 0 00-15-14.99zM376.97 198.35H248.34a15.01 15.01 0 00-15 14.99v70.06a15.018 15.018 0 0015 15h128.63a15.018 15.018 0 0015-15v-70.06a15.01 15.01 0 00-15-14.99zM587.28 198.35H458.65a15.01 15.01 0 00-15 14.99v70.06a15.018 15.018 0 0015 15h128.63a15.018 15.018 0 0015-15v-70.06a15.01 15.01 0 00-15-14.99zM166.66 345.35H99.63q-.375 8.28-.38 16.65a362.657 362.657 0 009.66 83.4h57.75a15.018 15.018 0 0015-15v-70.06a15.01 15.01 0 00-15-14.99zM376.97 345.35H248.34a15.01 15.01 0 00-15 14.99v70.06a15.018 15.018 0 0015 15h128.63a15.018 15.018 0 0015-15v-70.06a15.01 15.01 0 00-15-14.99zM587.28 345.35H458.65a15.01 15.01 0 00-15 14.99v70.06a15.018 15.018 0 0015 15h128.63q.615 0 1.23-.06a14.303 14.303 0 004.66-1.15 15.02 15.02 0 009.11-13.65v-70.2a15.01 15.01 0 00-15-14.99z"
        style={{ isolation: "isolate" }}
      ></path>
      <circle cx="169.344" cy="285.373" r="29.759" fill="#0fd24f"></circle>
      <path
        fill="#fff"
        d="M165.284 300.861a3.31 3.31 0 01-2.648-1.324l-8.118-10.825a3.31 3.31 0 115.296-3.972l5.312 7.081 13.642-20.463a3.31 3.31 0 115.509 3.673l-16.238 24.356a3.312 3.312 0 01-2.662 1.473l-.093.001z"
      ></path>
      <circle cx="386.344" cy="288.373" r="29.759" fill="#0fd24f"></circle>
      <path
        fill="#fff"
        d="M382.284 303.861a3.31 3.31 0 01-2.648-1.324l-8.118-10.825a3.31 3.31 0 115.296-3.972l5.312 7.081 13.642-20.463a3.31 3.31 0 015.509 3.673l-16.238 24.356a3.312 3.312 0 01-2.662 1.473l-.093.001z"
      ></path>
      <path
        fill="#a0616a"
        d="M811.31 448.735l-9.315-90.164-2.458-37.108 26.072-4.714s3.904 25.51 7.32 40.48c3.37 14.773-4.49 89.527-4.699 91.498a12.685 12.685 0 11-16.92.008z"
      ></path>
      <path
        fill="#a0616a"
        d="M744.583 717.124L731.653 720.08 714.099 671.614 733.182 667.251 744.583 717.124z"
      ></path>
      <path
        fill="#2f2e41"
        d="M746.92 712.15l-3.3.75-.34.08-15.09-2.46-.44-.07-6.3 7.52a16.652 16.652 0 00-12.52 19.94l.02.09.11.44 1.92-.44 39.77-9.1z"
      ></path>
      <path
        fill="#a0616a"
        d="M821.665 725.578L808.401 725.578 802.092 674.418 821.667 674.419 821.665 725.578z"
      ></path>
      <path
        fill="#2f2e41"
        d="M821.32 721.25l-1.16-.47-13-5.29-.42-.17-7.81 5.93a16.644 16.644 0 00-16.65 16.64v.54l36.61.01h6.16v-17.19zM801.492 451.767l11.156 105.98s14.85 106.118 12.062 120.76.697 16.733.697 16.733l-25.448-1.812s.697-9.064-3.487-13.248-2.091-16.733-2.091-16.733l-49.366-172.635-29.981 90.642s18.94 73.505 18.243 74.9 12 32 12 32l-28.408 9.42s3.486-11.156-1.395-14.642-9.064-.697-4.88-6.973-32.961-52.36-32.144-82.06c.245-8.935.542-18.521.9-28.343 1.888-51.973 5.455-110.536 11.978-113.99 11.853-6.274 110.164 0 110.164 0z"
      ></path>
      <circle cx="748.66" cy="209.744" r="27.439" fill="#a0616a"></circle>
      <path
        fill="#3f3d56"
        d="M840.277 377.353l-37.259 9.458.26-10.458-2.829-3-5.65-29.597-5.999-76.627 20.589 10.92.025.02a55.662 55.662 0 018.928 13.219c7.761 15.779 15.217 50.533 19.12 70.72a2.203 2.203 0 00.743 1.07 11.415 11.415 0 012.072 14.275z"
      ></path>
      <path
        fill="#a0616a"
        d="M620.1 411.262s51.428-54.219 51.907-55.24c3.593-27.281 13.925-39.315 15.474-38.009l26.668 9.239s-7.932 25.119-13.303 40.04c-5.132 14.256-64.278 49.08-65.265 50.798a12.685 12.685 0 11-15.48-6.828z"
      ></path>
      <path
        fill="#3f3d56"
        d="M726.96 272.064s-23.09-11.316-32.045 10.909c-4.91 12.547-17.466 42.998-23.723 61.743a1.4 1.4 0 01-.17.336 15.24 15.24 0 00-2.597 8.41 2.863 2.863 0 01-.067.649s25.372 4.943 32.92 18.242l2.992-3.248.532-2.276z"
      ></path>
      <path
        fill="#3f3d56"
        d="M810.975 463.318c-53.139 10.974-123.559-7.893-123.559-7.893l18.786-81.43-6.679-85.217c-.815-10.421 5.12-20.19 14.354-23.652l12.75-4.775 10.539-15.088 30.103.988 12.034 15.84 9.497 5.038 20.589 10.92.09.045v.045l-6.46 62.672-3.897 37.788s11.834 42.5 9.406 48.136c-2.429 5.628-.317 2.91.272 5.918s2.392 3.198 2.664 9.125c.28 5.917-.49 21.54-.49 21.54z"
      ></path>
      <path
        fill="#2f2e41"
        d="M778.426 199.974c-2.169-2.14 1.095-7.803.479-10.786-.707-3.42-4.476-3.52-5.375-6.894-.967-3.63-4.835-5.607-8.411-6.758a54.391 54.391 0 00-28.347-1.298 108.201 108.201 0 00-12.264 3.778c-2.767.974-.098-1.677-2.866-.703a4.345 4.345 0 00-2.595 1.722c-.498.941.173 2.446 1.22 2.255l-10.137 4.47 5.685 5.956c-2.694-.767-5.566 2.445-4.504 5.037a5.693 5.693 0 004.357 3.016c3.043.592.735 3.489 3.835 3.433s24.72-6.412 26.227-3.702c1.073 1.93.955 4.485 2.487 6.075 1.961 2.034 5.384 1.29 7.88-.034 2.495-1.324 5.026-3.144 7.833-2.826 2.931.331 5.148 5.747 5.87 8.607.721 2.86.265 5.869-.194 8.782-.603 3.827-6.644 7.653-7.247 11.48 6.704 1.853 13.895-4.046 16.546-14.304-.264-6.485 4.14-12.748-.48-17.306z"
      ></path>
      <circle cx="594.344" cy="430.373" r="29.759" fill="#0fd24f"></circle>
      <path
        fill="#fff"
        d="M590.284 445.861a3.31 3.31 0 01-2.648-1.324l-8.118-10.825a3.31 3.31 0 115.296-3.972l5.312 7.081 13.642-20.463a3.31 3.31 0 115.509 3.673l-16.238 24.356a3.312 3.312 0 01-2.662 1.473l-.093.001z"
      ></path>
      <path
        fill="#ccc"
        d="M914.946 739.191a1.186 1.186 0 01-1.19 1.19h-280.29a1.19 1.19 0 010-2.38h280.29a1.187 1.187 0 011.19 1.19z"
      ></path>
    </svg>
  );
}

export default StrategySolutions;
