import React, { useState } from "react";
import axios from "axios";

//Import Form style
import "./index.css";
// Import Components
import Container from "../../components/Container";
import P from "../../components/P";

function Email() {
  const [from, setFrom] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [mail, setMail] = useState("no-mail");

  const submitForm = (event) => {
    event.preventDefault();
    const email = {
      from,
      text,
    };

    axios
      .post(`${process.env.REACT_APP_SERVER}/form`, email)
      .then((response) => {
        if (response.data.status === 202) {
          setMail("sent");
        } else {
          setMail("error");
        }
      });
  };

  return (
    <Container className="Email" id="contactos" margin="1rem 0">
      <Container laptopPadding="0 5rem 6rem " maxWidth="1000px" margin="0 auto">
        <Container flex className="heading" alignItems="center"></Container>
        <Container flex margin="2rem 0 0" justifyContent="center">
          <Container laptopWidth="60%" tabletWidth="80%" width="90%">
            <form
              style={{ display: "flex", flexDirection: "column" }}
              onSubmit={submitForm}
            >
              <input
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                placeholder="Email:"
                type="email"
                name="To_Email"
                required
                className="inputs"
              />
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Mensagem:"
                type="text"
                name="Text"
                required
                className="inputs text_input"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "8px 0",
                }}
              >
                <button className="button" type="submit">
                  Submeter
                </button>
              </div>
            </form>
            {mail === "sent" ? (
              <Container
                flex
                justifyContent="center"
                border="3px solid rgb(15, 209, 79)"
                margin="5%"
              >
                <P color="#rgb(15, 209, 79)" fontWeight="bold" margin="0">
                  {" "}
                  Email enviado com sucesso.
                </P>
              </Container>
            ) : null}
            {mail === "error" ? (
              <Container
                flex
                justifyContent="center"
                border="3px solid rgb(173 49 49)"
                margin="5%"
              >
                <P color="rgb(173 49 49)" fontWeight="bold" margin="0">
                  {" "}
                  Erro no envio do email. Tente novamente mais tarde.
                </P>
              </Container>
            ) : null}
          </Container>
        </Container>
      </Container>
    </Container>
  );
}

export default Email;
