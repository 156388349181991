import React from "react";

function TendencySolutions() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="150"
      data-name="Layer 1"
      viewBox="0 0 751.951 555.868"
    >
      <path
        fill="#e6e6e6"
        d="M533.74 407.697H128.805a13.625 13.625 0 01-13.61-13.609V64.365a13.625 13.625 0 0113.61-13.609H533.74a13.625 13.625 0 0113.61 13.61v329.722a13.625 13.625 0 01-13.61 13.61z"
      ></path>
      <path
        fill="#fff"
        d="M137.986 60.443a13.124 13.124 0 00-13.108 13.11v311.356a13.124 13.124 0 0013.108 13.109H415.15A122.66 122.66 0 00537.67 275.497V73.552a13.124 13.124 0 00-13.109-13.11z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M459.9 200.474h-73.424a5.782 5.782 0 01-5.776-5.776v-36.545a5.782 5.782 0 015.776-5.776H459.9a5.782 5.782 0 015.776 5.776c4.543 12.932 5.189 25.22 0 36.545a5.782 5.782 0 01-5.776 5.776z"
      ></path>
      <path
        fill="#fff"
        d="M451.202 196.942H388.82a4.913 4.913 0 01-4.907-4.907v-31.049a4.913 4.913 0 014.907-4.907h62.382a4.913 4.913 0 014.907 4.907c4.21 11.058 4.887 21.521 0 31.049a4.913 4.913 0 01-4.907 4.907z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M447.837 219.617h-49.461a5.488 5.488 0 01-.02-10.975h49.481a5.488 5.488 0 010 10.975zM414.581 164.463a2.153 2.153 0 00-2.145 2.148v23.261a2.145 2.145 0 003.207 1.864l20.531-11.707a2.144 2.144 0 00-.01-3.733l-20.53-11.555a2.142 2.142 0 00-1.053-.278z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M714.114 511.364a9.168 9.168 0 00-9.789-3.129l-.384.114-3.448 13.46c-.516-2.007-1.621-6.535-2.318-10.967l-.06-.367-.342.15a46.75 46.75 0 00-10.084 6.078 48.361 48.361 0 00-18.083 36.42l-.006.257-.006.163.456-.163 9.796-3.5a29.24 29.24 0 0023.578-8.793c3.958-4.174 6.673-9.543 9.297-14.738.769-1.514 1.555-3.075 2.36-4.577a9.32 9.32 0 00-.967-10.408z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M463.97 168.146a7.098 7.098 0 009.517 5.28l29.275 34.056 3.706-12.572-29.023-31.157a7.136 7.136 0 00-13.475 4.393z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M581.955 545.239L572.267 545.239 567.658 507.87 581.957 507.87 581.955 545.239z"
      ></path>
      <path
        fill="#2f2e41"
        d="M581.696 542.075l-10.344-4.208-.296-.121-5.71 4.329a12.161 12.161 0 00-12.16 12.159v.395h31.237v-12.554z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M651.067 545.239L641.379 545.239 636.77 507.87 651.069 507.87 651.067 545.239z"
      ></path>
      <path
        fill="#2f2e41"
        d="M650.809 542.075l-10.344-4.208-.297-.121-5.71 4.329a12.161 12.161 0 00-12.16 12.159v.395h31.237v-12.554zM628.202 322.09s12.151 12.641 12.151 23.029-3.038 24.65 0 27.771 6.836 6.92 2.279 11.476-6.835 6.835-3.798 9.873 1.52 56.201 1.52 56.201l15.948 71.39H636.21l-25.475-71.39-6.835-59.239-22.106 56.96 2.44 74.282-21.005-.612-8.695-78.986s12.91-75.134 10.633-77.06 0-5.812 0-5.812l6.075-28.77z"
      ></path>
      <path
        fill="#3f3d56"
        d="M604.41 232.456s4.132-16.281-14.903-20.95-73.45-10.665-73.45-10.665l-27.499-29.18-9.318 13.533 30.827 38.308 73.043 15.003z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M483.769 184.196a12.89 12.89 0 001.979-3.665l3.943-9.69-1.024-.043a10.23 10.23 0 00-12.095 9.315 6.66 6.66 0 00.645 3.621 3.947 3.947 0 002.89 2.12 4.364 4.364 0 003.662-1.658zM567.363 335.083l-2.278 16.516s4.557 4.894 6.835 4.282 10.633-4.172 10.633-4.172 9.873 5.396 15.19 6.008 33.416-3.059 34.175-4.894 9.587-9.019 9.587-9.019l-3.51-20.955z"
      ></path>
      <path
        fill="#3f3d56"
        d="M641.359 277.303s-1.93 15.926-.41 17.392c1.519 1.458-1.52 10.686-4.557 16.762s2.278 6.835 2.278 11.392 2.279 21.265 2.279 21.265-23.544 1.519-27.341-2.278-9.874-.76-18.228 7.594-26.498 1.52-30.295-2.278 2.954-17.468 5.233-20.119-2.195.418-4.474-7.982 2.195-7.594 5.993-11.392 0-10.632-3.038-17.468-.76-57.72-.76-69.871 16.709-13.67 16.709-13.67l6.615-8.317 4.86-6.114 9.524-.38.25-.007 9.213-.372 4.473 5.21 9.873 11.498 11.393 2.279c9.113 3.038 6.835 22.025 6.835 22.025z"
      ></path>
      <circle cx="605.277" cy="169.023" r="19.522" fill="#ffb6b6"></circle>
      <path
        fill="#2f2e41"
        d="M627.913 162.455c-5.37-3.293-4.682-11.903-9.855-14.91a18.424 18.424 0 00-17.033-.715c-3.644-1.559-8.01-.315-10.925 2.5s-4.493 6.969-4.958 11.145a41.705 41.705 0 00.893 12.526l.869-4.95 15.39-3.363-4.087 3.672a27.956 27.956 0 0020.437-3.196c6.7 3.004 5.485 9.75-.396 18.701 2.278 3.798 15.783-17.657 9.665-21.41z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M619.683 196.67a1.542 1.542 0 01-.136.099 10.227 10.227 0 01-5.324 1.443 9.414 9.414 0 01-6.782-2.127c-.995-.942-4.952 1.048-7.55 2.15a11.46 11.46 0 01-8.528.098l4.86-6.114 8.011-1.776h2l8.976 1.017z"
      ></path>
      <path
        fill="#cacaca"
        d="M750.766 555.56l-271.75.308a1.19 1.19 0 11-.012-2.381h.012l271.75-.307a1.19 1.19 0 010 2.38z"
      ></path>
      <path
        fill="#3f3d56"
        d="M425.383 155.694L423.383 155.694 423.383 12.651 502.04 12.651 502.04 14.651 425.383 14.651 425.383 155.694z"
      ></path>
      <path
        fill="#0fd24f"
        d="M563.213 0h-53a13 13 0 100 26h53a13 13 0 000-26z"
      ></path>
      <circle cx="423.223" cy="156.834" r="11.946" fill="#0fd24f"></circle>
      <path
        fill="#f2f2f2"
        d="M279.272 201.722l52.406-12.127 15.042-3.48c1.882-.436 1.1-3.333-.782-2.898l-52.406 12.128-15.043 3.48c-1.881.436-1.1 3.333.783 2.897z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M333.388 170.14l28.055 12.905a9.289 9.289 0 012.655 1.438c.774.758.21 1.488-.422 2.148a63.263 63.263 0 01-5.971 4.929l-11.952 9.717c-1.5 1.22.616 3.349 2.103 2.14l10.41-8.463 5.205-4.232c1.475-1.199 3.27-2.422 3.862-4.332 1.245-4.013-3.668-5.608-6.399-6.864l-12.217-5.62-13.801-6.349c-1.74-.8-3.28 1.777-1.528 2.583z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M720.541 268.384c-4.998-3.389-11.07-4.648-17.005-5.075a182.41 182.41 0 00-20.252.023q-10.51.417-20.976 1.521-10.452 1.107-20.817 2.9a153.828 153.828 0 00-18.331 4.012 49.195 49.195 0 00-15.852 7.655 28.072 28.072 0 00-9.315 12.324 22.891 22.891 0 00-.9 14.456 17.234 17.234 0 003.408 6.331 23.744 23.744 0 006.348 5.026c4.959 2.831 10.576 4.534 15.06 8.158a21.29 21.29 0 013.216 3.197 5.835 5.835 0 002.582 2.032 5.275 5.275 0 005.669-1.46 19.189 19.189 0 002.23-3.966l2.366-4.564a6.382 6.382 0 01.592-1.141c.177-.211-.14.004-.08-.051.034-.03.102-.03.14-.054a3.427 3.427 0 01.611-.235q3.283-1.251 6.594-2.424c8.15-2.904 16.397-5.582 24.775-7.75a119.875 119.875 0 0113.096-2.74c5.716-.814 11.518-.72 17.26-1.276 6.182-.599 12.56-1.85 17.861-5.254a23.193 23.193 0 009.602-11.493 16.08 16.08 0 00.801-7.115c-.688-5.435-4.24-10.025-8.683-13.037z"
      ></path>
      <path
        fill="#fff"
        d="M710.312 271.437c-4.517-3.062-10.004-4.2-15.367-4.586a164.84 164.84 0 00-18.3.022q-9.498.376-18.957 1.374-9.445 1-18.811 2.62a139.01 139.01 0 00-16.566 3.626 44.456 44.456 0 00-14.325 6.917 25.368 25.368 0 00-8.417 11.138 20.686 20.686 0 00-.813 13.063 15.574 15.574 0 003.079 5.721 21.457 21.457 0 005.737 4.542c4.48 2.559 9.557 4.097 13.61 7.372a19.239 19.239 0 012.905 2.89 5.273 5.273 0 002.334 1.835 4.767 4.767 0 005.122-1.319 17.34 17.34 0 002.016-3.584l2.138-4.124a5.767 5.767 0 01.534-1.032c.16-.19-.126.004-.071-.046.03-.027.092-.027.126-.049a3.097 3.097 0 01.552-.211q2.966-1.131 5.96-2.191c7.363-2.625 14.816-5.044 22.387-7.003a108.324 108.324 0 0111.835-2.476c5.165-.736 10.408-.65 15.597-1.154 5.586-.541 11.35-1.672 16.141-4.748a20.959 20.959 0 008.677-10.386 14.531 14.531 0 00.724-6.43c-.623-4.91-3.831-9.058-7.847-11.78z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M700.852 276.967l-73.601 13.575a1.628 1.628 0 11-.6-3.201l.01-.002 73.6-13.575a1.628 1.628 0 11.591 3.203zM702.665 286.796l-73.601 13.575a1.628 1.628 0 11-.6-3.2l.01-.003 73.6-13.574a1.628 1.628 0 11.591 3.202zM639.454 308.619l-8.577 1.581a1.628 1.628 0 01-.59-3.202l8.576-1.582a1.628 1.628 0 01.591 3.203z"
      ></path>
      <circle
        cx="856.01"
        cy="514.742"
        r="6.921"
        fill="#d1d3d4"
        transform="rotate(-10.45 -196.792 1653.59)"
      ></circle>
      <path
        fill="#e6e6e6"
        d="M252.341 198.623h-62.866a11.02 11.02 0 01-11.008-11.008v-41.433a11.02 11.02 0 0111.008-11.008h62.866a11.02 11.02 0 0111.008 11.008v41.433a11.02 11.02 0 01-11.008 11.008z"
      ></path>
      <path
        fill="#fff"
        d="M245.433 196.39h-54.714a9.592 9.592 0 01-9.58-9.58v-36.06a9.592 9.592 0 019.58-9.58h54.714a9.592 9.592 0 019.58 9.58v36.06a9.592 9.592 0 01-9.58 9.58z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M261.196 235.95H180.53a1.882 1.882 0 010-3.764h80.666a1.882 1.882 0 010 3.765zM261.196 225.195H180.53a1.882 1.882 0 010-3.764h80.666a1.882 1.882 0 010 3.764zM261.196 246.706H180.53a1.882 1.882 0 110-3.764h80.666a1.882 1.882 0 110 3.764zM261.196 257.462H180.53a1.882 1.882 0 110-3.765h80.666a1.882 1.882 0 110 3.765zM261.196 268.217H180.53a1.882 1.882 0 110-3.764h80.666a1.882 1.882 0 110 3.764zM261.196 278.972H180.53a1.882 1.882 0 010-3.764h80.666a1.882 1.882 0 010 3.764z"
      ></path>
      <path
        fill="#3f3d56"
        d="M216.83 254.306L218.83 254.306 218.83 331.349 74.173 331.349 74.173 329.349 216.83 329.349 216.83 254.306z"
      ></path>
      <path
        fill="#0fd24f"
        d="M13 344h53a13 13 0 000-26H13a13 13 0 000 26z"
      ></path>
      <circle cx="219.223" cy="248.166" r="11.946" fill="#0fd24f"></circle>
      <path
        fill="#f2f2f2"
        d="M280.968 251.485c17.538 3.728 13.077 4.455 30.616 8.183l15.102 3.21c1.89.401 2.407-2.554.517-2.956-17.539-3.728-13.078-4.455-30.616-8.183l-15.103-3.21c-1.889-.401-2.406 2.554-.516 2.956z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M321.364 242.763l19.96 23.562a9.288 9.288 0 011.798 2.426c.38 1.015-.439 1.437-1.29 1.768a63.258 63.258 0 01-7.497 1.94l-14.94 3.748c-1.876.47-.858 3.295 1 2.83l13.013-3.265 6.506-1.633c1.844-.462 3.988-.811 5.333-2.291 2.825-3.11-.952-6.633-2.894-8.927l-8.693-10.26-9.82-11.592c-1.237-1.461-3.723.222-2.476 1.694z"
      ></path>
      <circle cx="384.653" cy="317.695" r="27.78" fill="#e6e6e6"></circle>
      <circle cx="381.945" cy="320.814" r="21.119" fill="#fff"></circle>
      <path
        fill="#e6e6e6"
        d="M378.155 335.272a3.09 3.09 0 01-2.471-1.236l-7.58-10.105a3.09 3.09 0 114.943-3.71l.002.002 4.958 6.61 12.735-19.101a3.09 3.09 0 115.143 3.428l-15.158 22.736a3.091 3.091 0 01-2.486 1.375l-.086.001z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M644.05 346.313a7.098 7.098 0 001.794-10.735L668.15 296.6l-13.087.711-19.659 37.772a7.136 7.136 0 008.647 11.23z"
      ></path>
      <path
        fill="#3f3d56"
        d="M618.927 209.687s23.624-7.594 29.7 10.633 26.5 67.593 26.5 67.593l-21.943 44.809-9.874-10.633 10.552-31.138-30.379-45.568z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M653.936 328.146a12.89 12.89 0 00-2.67-3.196l-7.539-7.255-.419.935a10.23 10.23 0 004.168 14.686 6.661 6.661 0 003.602.744 3.946 3.946 0 003.041-1.899 4.364 4.364 0 00-.183-4.015z"
      ></path>
      <path
        fill="#3f3d56"
        d="M384.412 355.068L382.412 355.068 382.412 432.111 450.069 432.111 450.069 430.111 384.412 430.111 384.412 355.068z"
      ></path>
      <path
        fill="#0fd24f"
        d="M524.242 431.762a13.012 13.012 0 00-13-13h-53a13 13 0 000 26h53a13.012 13.012 0 0013-13z"
      ></path>
      <circle cx="382.019" cy="348.928" r="11.946" fill="#0fd24f"></circle>
    </svg>
  );
}

export default TendencySolutions;