const displayData = [
  {
    icon: "/icons/ozone.png",
    title: "Alterações climáticas",
    text: "Lorem Ipsum, lorem ipsum lorem lorem lorem ",
  },
  {
    icon: "/icons/city.png",
    title: "Estratégias ESG",
    text: "Lorem Ipsum, lorem ipsum lorem lorem lorem ",
  },
  {
    icon: "/icons/task-list.png",
    title: "Produção e consumo sustentáveis",
    text: "Lorem Ipsum, lorem ipsum lorem lorem lorem ",
  },
  {
    icon: "/icons/meio-ambiente.png",
    title: "Preservação ambiental",
    text: "Lorem Ipsum, lorem ipsum lorem lorem lorem ",
  },
  {
    icon: "/icons/atom.png",
    title: "Economia circular",
    text: "Lorem Ipsum, lorem ipsum lorem lorem lorem ",
  },
  {
    icon: "/icons/puzzle.png",
    title: "Inclusão e diversidade",
    text: "Lorem Ipsum, lorem ipsum lorem lorem lorem ",
  },
];

export default displayData;
