import { createGlobalStyle } from "styled-components";

import BobbyJones from "./fonts/BobbyJones/bobby-jones-soft-regular.woff";

import LidlFont_Regular from "./fonts/LidlFont/LidlFontCondPro-Regular.woff";
import LidlFont_Bold from "./fonts/LidlFont/LidlFontPro-Bold.woff";
import LidlFont_Semi from "./fonts/LidlFont/LidlFontPro-Semibold.woff";

const GlobalStyles = createGlobalStyle`

 @font-face {
    font-family: Bobby Jones;
        src: local('Bobby Jones'), local('Bobby Jones'),
        url(${BobbyJones}) format('woff');
    }

 @font-face {
    font-family: 'LildFont';
        src: local('LidlFont'), local('LidlFont'),
        url(${LidlFont_Bold}) format('woff');
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
    font-family: 'LidlFont';
        src: local('LidlFont'), local('LidlFont'),
        url(${LidlFont_Semi}) format('woff');
        font-weight: 900;
        font-style: normal;
    }
    @font-face {
    font-family: 'LidlFont';
        src: local('LidlFont'), local('LidlFont'),
        url(${LidlFont_Regular}) format('woff');
        font-weight: 500;
        font-style: normal;
    }

  body {
    background-color: ${(props) => props.theme.main.colors.secondary};
    font-family: 'LidlFont', sans-serif;
  }
  p {
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 1.5;
  }

  #root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
`;

export default GlobalStyles;
