import PropTypes from "prop-types";
import Wrapper from "./Wrapper";

const Container = (props) => {
  return <Wrapper {...props}>{props.children}</Wrapper>;
};

Container.defaultProps = {
  width: "",
  margin: "0",
  padding: "0",
};

Container.propTypes = {
  width: PropTypes.string,
  flex: PropTypes.bool,
  column: PropTypes.bool,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundPosition: PropTypes.string,
  backgroundSize: PropTypes.string,
  backgroundRadius: PropTypes.string,
  writingMode: PropTypes.string,
  textAlign: PropTypes.string,
  transfrom: PropTypes.string,
  borderBottom: PropTypes.string,
  laptopPadding: PropTypes.string,
  tabletPadding: PropTypes.string,
};

export default Container;
