import TendencySolutions from "../../util/SVG/Icons/tendency-solutions.js";
import StrategySolutions from "../../util/SVG/Icons/strategy-solutions.js";
import CommunicationSolutions from "../../util/SVG/Icons/communication-solutions.js";

const cardsData = [
  {
    icon: <StrategySolutions />,
    title: "Sustainable Lab",
    point1: "Análise de tendências em sustentabilidade por setor",
    point2: "Análise do contexto sociopolítico",
    point3: "Análise de benchmark",
  },
  {
    icon: <TendencySolutions />,
    title: "Activation Lab",
    point1: "Strategic planning",
    point2: "Brand purpose e posicionamento",
    point3: "Definição e implementação do plano de ação",
  },
  {
    icon: <CommunicationSolutions />,
    title: "Creative Lab",
    point1: "Assessoria mediática e relações públicas",
    point2: "Endorsers engagement",
    point3: "Public affairs e gestão de stakeholders",
  },
];

export default cardsData;
