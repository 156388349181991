import React from "react";

function Social() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 691.927 626.302"
    >
      <path
        fill="#f0f0f0"
        d="M787.33 526.157c-2.712-20.478-15.576-40.575-35.178-47.09a95.176 95.176 0 01.513 65.342c-2.94 8.15-7.058 16.908-4.168 25.075 1.799 5.082 6.13 8.946 10.957 11.346 4.828 2.4 10.173 3.52 15.45 4.616l1.044.85c8.215-18.954 14.093-39.66 11.382-60.139z"
      ></path>
      <path
        fill="#fff"
        d="M751.96 479.455a81.35 81.35 0 0120.586 45.622 35.032 35.032 0 01-.311 10.972 20.092 20.092 0 01-4.924 9.359c-2.232 2.492-4.805 4.783-6.393 7.775a12.303 12.303 0 00-.521 10.048c1.455 4.07 4.285 7.373 7.166 10.508 3.198 3.481 6.575 7.046 7.96 11.683.167.562 1.03.268.862-.293-2.409-8.067-10.295-12.598-14.096-19.852-1.774-3.385-2.538-7.324-.93-10.925 1.408-3.148 4.059-5.513 6.341-8.017a21.44 21.44 0 005.155-8.971 32.386 32.386 0 00.732-10.92 78.924 78.924 0 00-5.954-23.938 82.792 82.792 0 00-15.075-23.738c-.393-.433-.987.257-.597.687z"
      ></path>
      <path
        fill="#fff"
        d="M772.258 519.57a12.205 12.205 0 009.188-12.859c-.05-.583-.96-.531-.909.053a11.302 11.302 0 01-8.572 11.944c-.569.14-.273 1.001.293.862zM768.61 544.293a23.524 23.524 0 01-10.612-13.465c-.17-.56-1.032-.268-.862.293a24.469 24.469 0 0011.07 13.988c.508.296.908-.522.404-.816zM763.16 494.66a6.909 6.909 0 01-6.549-.28c-.503-.301-.903.517-.403.817a7.742 7.742 0 007.245.326.47.47 0 00.285-.577.458.458 0 00-.577-.285z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M692.852 516.151c.309.198.618.395.927.6a90.982 90.982 0 0111.705 8.99c.286.251.573.51.851.77a95.916 95.916 0 0121.056 28.349 93.146 93.146 0 015.207 13.16c1.932 6.232 3.531 13.143 7.298 18.223a15.977 15.977 0 001.257 1.527l33.678-.267c.076-.04.153-.07.23-.11l1.345.051c-.056-.237-.12-.483-.175-.72-.032-.139-.071-.277-.103-.415-.024-.092-.048-.184-.064-.268a1.274 1.274 0 00-.024-.084c-.016-.085-.04-.162-.055-.238q-.524-2.06-1.078-4.118c0-.008 0-.008-.008-.015a136.96 136.96 0 00-11.765-30.168c-.156-.283-.312-.573-.483-.856a88.883 88.883 0 00-8.08-12.047 78.577 78.577 0 00-5.28-5.944 65.342 65.342 0 00-16.447-12.118c-12.13-6.282-26.133-8.615-39.011-4.617-.33.103-.652.205-.981.315z"
      ></path>
      <path
        fill="#fff"
        d="M692.931 516.578a81.35 81.35 0 0143.904 24.033 35.032 35.032 0 016.357 8.948 20.092 20.092 0 011.703 10.436c-.282 3.335-.956 6.713-.423 10.057a12.303 12.303 0 005.633 8.337c3.612 2.373 7.862 3.307 12.049 4.076 4.649.854 9.492 1.667 13.389 4.536.472.347.984-.406.512-.753-6.78-4.991-15.805-3.861-23.207-7.364-3.454-1.635-6.436-4.32-7.32-8.164-.771-3.36-.079-6.845.236-10.218a21.44 21.44 0 00-1.285-10.267 32.386 32.386 0 00-5.99-9.16 78.924 78.924 0 00-19.166-15.528 82.792 82.792 0 00-26.33-9.877c-.573-.11-.632.8-.062.908z"
      ></path>
      <path
        fill="#fff"
        d="M733.288 536.387a12.205 12.205 0 00-.404-15.799c-.392-.435-1.087.154-.695.59a11.302 11.302 0 01.347 14.697c-.37.454.385.964.752.512zM745.261 558.324a23.524 23.524 0 01-16.58-4.362c-.473-.346-.985.407-.512.752a24.469 24.469 0 0017.262 4.504c.583-.07.41-.964-.17-.894zM711.028 521.976a6.909 6.909 0 01-5.398 3.719c-.583.062-.41.956.17.894a7.742 7.742 0 005.981-4.1.47.47 0 00-.12-.633.458.458 0 00-.633.12z"
      ></path>
      <path
        fill="#a0616a"
        d="M565.392 320.136a6.618 6.618 0 0010.136.478l21.566 9.379-2.1-12.039-20.158-7.06a6.653 6.653 0 00-9.444 9.242z"
      ></path>
      <path
        fill="#3f3d56"
        d="M585.014 313.334l41.197 5.34 42.485-25.593a18.784 18.784 0 0126.807 8.35 18.804 18.804 0 01-8.434 24.393l-57.089 20.344-.107.038-50.09-22.312z"
      ></path>
      <path
        fill="#a0616a"
        d="M690.298 579.764L681.54 579.764 677.374 545.984 690.3 545.985 690.298 579.764z"
      ></path>
      <path
        fill="#2f2e41"
        d="M692.531 588.253h-28.238v-.358a10.992 10.992 0 0110.991-10.99h.001l5.158-3.914 9.624 3.914h2.465z"
      ></path>
      <path
        fill="#a0616a"
        d="M656.36 574.245L647.818 576.174 636.313 544.142 648.921 541.295 656.36 574.245z"
      ></path>
      <path
        fill="#2f2e41"
        d="M660.409 582.034l-27.545 6.22-.079-.35a10.992 10.992 0 018.3-13.141h.001l4.17-4.954 10.25 1.698 2.404-.543zM701.39 373.254c4.106 12.383 3.672 25.826-.987 40.253l-1.992 81.228s2.968 53.268-6.607 72.012h-13.886l-6.876-76.877-.863-51.6-29.77 43.533 18.961 83.687-17.09 1.528-30.77-90.82 37.963-88.111z"
      ></path>
      <path
        fill="#3f3d56"
        d="M645.176 385.226l3.425-8.561-2.265-7.36-.516-71.322v-.055l8.218-13.103.066-8.288 22.946-.56 2.489 6.32 4.924 2.944a35.646 35.646 0 0117.307 31.18l-.89 48.12 2.29 4.581a9.638 9.638 0 01-5.68 13.49l-50.493 8.077z"
      ></path>
      <circle cx="663.476" cy="247.27" r="20.849" fill="#a0616a"></circle>
      <path
        fill="#2f2e41"
        d="M685.186 251.815c.622-2.62.336-6.306-.015-8.944a25.035 25.035 0 00-10.596-17.383 7.633 7.633 0 00-3.802-1.511 3.282 3.282 0 00-3.3 1.996 6.842 6.842 0 00-3.86-3.828 12.058 12.058 0 00-5.495-.74 20.08 20.08 0 00-16.752 11.975c-.557 1.323-2.112-3.564-2.818-2.314a5.557 5.557 0 01-3.151 2.77c-1.383.385-1.944 6.081-2.488 4.753a6.332 6.332 0 006.053 8.829 34.872 34.872 0 004.143-.793 10.33 10.33 0 0111.31 7.73 13.685 13.685 0 011.8-4.005 4.632 4.632 0 013.716-2.042 5.204 5.204 0 013.576 2.056c.918 1.077 1.607 2.335 2.532 3.407 2.713 3.145 6.382 10.48 10.248 9.945 3.052-.422 8.05-8.324 8.9-11.9z"
      ></path>
      <path
        fill="#a0616a"
        d="M585.754 375.47a6.618 6.618 0 009.075-4.54l23.398-2.363-7.72-9.474-21.035 3.697a6.653 6.653 0 00-3.718 12.68z"
      ></path>
      <path
        fill="#3f3d56"
        d="M599.544 359.943l38.547-15.487 24.544-43.1a18.784 18.784 0 0127.467-5.824 18.804 18.804 0 014.571 25.401l-39.85 45.662-.075.086-54.603 5.031z"
      ></path>
      <path
        fill="#ccc"
        d="M806.959 589.444H458.97a1.19 1.19 0 110-2.381h347.988a1.19 1.19 0 110 2.381z"
      ></path>
      <path
        fill="#0fd24f"
        d="M582.066 395.8h-158.14a4.161 4.161 0 01-4.156-4.156v-64.166a4.161 4.161 0 014.157-4.157h158.14a4.161 4.161 0 014.156 4.157v64.166a4.161 4.161 0 01-4.157 4.157z"
      ></path>
      <path
        fill="#e4e4e4"
        d="M567.009 426.582H438.985a4.988 4.988 0 110-9.976h128.024c6.432-.09 6.496 10.066 0 9.976zM567.009 449.443H438.985a4.988 4.988 0 110-9.976h128.024c6.432-.09 6.496 10.067 0 9.976zM567.009 472.305H438.985a4.988 4.988 0 110-9.976h128.024c6.432-.09 6.496 10.066 0 9.976z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M29.01 225.61C1.173 189.033-10.041 137.136 10.68 96.106A211.783 211.783 0 00112.353 200.05c17.422 8.253 37.658 15.618 45.905 33.043 5.132 10.843 4.356 23.737.5 35.095-3.856 11.359-10.536 21.51-17.147 31.519l-.315 2.977C98.603 285.651 56.849 262.187 29.01 225.61z"
      ></path>
      <path
        fill="#fff"
        d="M11.59 96.42c1.707 37.609 15.44 74.915 39.062 104.299 5.107 6.353 10.841 12.353 17.7 16.83a44.71 44.71 0 0022.45 7.05c7.433.432 15.086.014 22.285 2.246 7.588 2.352 13.243 7.926 16.582 15.042 4.086 8.706 4.8 18.36 5.17 27.826.412 10.51.672 21.434 5.76 30.925.616 1.15-1.204 2.039-1.82.89-8.851-16.512-3.51-36.032-8.887-53.444-2.509-8.124-7.481-15.542-15.668-18.7-7.16-2.764-15.052-2.339-22.582-2.712-7.907-.39-15.312-2.063-22.207-6.075-7.055-4.105-13.035-9.893-18.282-16.087a175.62 175.62 0 01-28.147-47.123A184.227 184.227 0 019.57 96.272c-.059-1.3 1.962-1.142 2.021.149z"
      ></path>
      <path
        fill="#fff"
        d="M42.47 191.573a27.158 27.158 0 01-34.672-5.885c-.835-1 .682-2.345 1.518-1.342a25.148 25.148 0 0032.263 5.407c1.117-.67 2.002 1.152.89 1.82zM87.003 224.876a52.346 52.346 0 00-4.367-37.897c-.613-1.152 1.208-2.042 1.82-.891a54.447 54.447 0 014.464 39.445c-.337 1.261-2.252.598-1.917-.657zM17.76 137.988a15.373 15.373 0 009.899-10.715c.321-1.265 2.236-.6 1.917.657a17.227 17.227 0 01-10.924 11.878 1.047 1.047 0 01-1.355-.464 1.018 1.018 0 01.464-1.356z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M162.445 61.637c-.178.795-.356 1.591-.522 2.4a202.453 202.453 0 00-4.376 32.548 89.118 89.118 0 00-.137 2.55 213.43 213.43 0 0011.227 77.773 207.267 207.267 0 0012.422 28.938c6.725 12.868 15.04 26.284 17.061 40.211a35.555 35.555 0 01.41 4.382l-53.576 52.4c-.181.057-.352.128-.534.186l-2.043 2.19c-.285-.463-.57-.949-.855-1.412-.166-.268-.32-.547-.487-.815-.107-.183-.213-.365-.32-.524a2.831 2.831 0 01-.095-.17c-.107-.158-.19-.317-.285-.463q-2.403-4.07-4.758-8.19c-.012-.012-.012-.012-.012-.036-11.906-20.953-22.167-43.085-28.743-66.07-.198-.691-.408-1.395-.581-2.11a197.78 197.78 0 01-6.141-31.686 174.848 174.848 0 01-.989-17.664 145.397 145.397 0 016.956-44.925c9.293-28.94 27.738-54.585 54.336-68.472.68-.355 1.35-.699 2.042-1.041z"
      ></path>
      <path
        fill="#fff"
        d="M162.99 62.434c-21.28 31.055-32.777 69.11-31.607 106.794.253 8.147 1.22 16.39 4 24.094a44.71 44.71 0 0013.68 19.145c5.675 4.821 12.038 9.095 16.442 15.211 4.642 6.447 5.801 14.302 4.183 21.994-1.979 9.411-7.221 17.55-12.625 25.33-6 8.64-12.368 17.519-14.02 28.16-.2 1.289-2.19.903-1.99-.384 2.875-18.514 18.892-30.884 25.082-48.023 2.888-7.998 3.384-16.914-1.25-24.366-4.054-6.516-10.611-10.929-16.4-15.76-6.077-5.073-10.982-10.866-14.073-18.221-3.16-7.524-4.45-15.747-4.911-23.852a175.62 175.62 0 015.898-54.57 184.227 184.227 0 0126.067-56.887c.735-1.073 2.254.27 1.524 1.335z"
      ></path>
      <path
        fill="#fff"
        d="M130.357 156.999a27.158 27.158 0 01-24.14-25.573c-.064-1.302 1.956-1.462 2.02-.158a25.148 25.148 0 0022.504 23.742c1.296.137.905 2.125-.384 1.989zM145.864 210.402a52.346 52.346 0 0019.329-32.888c.205-1.289 2.194-.903 1.99.384a54.447 54.447 0 01-20.184 34.182c-1.03.805-2.159-.878-1.135-1.678zM142.89 99.338a15.373 15.373 0 0014.354-2.596c1.018-.817 2.147.867 1.135 1.678a17.227 17.227 0 01-15.873 2.908 1.047 1.047 0 01-.803-1.187 1.018 1.018 0 011.187-.803z"
      ></path>
      <circle cx="176.735" cy="141.337" r="22.445" fill="#2f2e41"></circle>
      <path
        fill="#d1d3d4"
        d="M299.55 357.332H141.41a4.161 4.161 0 01-4.157-4.157v-64.166a4.161 4.161 0 014.157-4.157h158.14a4.161 4.161 0 014.156 4.157v64.166a4.161 4.161 0 01-4.156 4.157z"
      ></path>
      <path
        fill="#e4e4e4"
        d="M284.492 388.113H156.468a4.988 4.988 0 010-9.976h128.024c6.432-.09 6.497 10.066 0 9.976zM284.492 410.974H156.468a4.988 4.988 0 110-9.976h128.024c6.432-.09 6.497 10.067 0 9.976zM284.492 433.835H156.468a4.988 4.988 0 010-9.975h128.024c6.432-.09 6.497 10.066 0 9.975z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M295.004 185.663a7.553 7.553 0 01-11.506 1.317l-56.036 23.92 1.474-13.87 54.609-21.172a7.594 7.594 0 0111.459 9.805z"
      ></path>
      <path
        fill="#0fd24f"
        d="M180.948 184.375s-6.928 6.729-.053 15.388 40.87 14.29 46.323 15.362 35.779-19.557 35.779-19.557l2.937-14.946-33.852 11.614z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M162.342 235.43l.546 10.366s-1.61 9.182-.286 11.163c1.314 1.971 37.178 2.22 37.178 2.22s2.37-12.774 4.411-23.956z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M265.457 372.482L272.955 370.968 270.809 340.682 259.743 342.918 265.457 372.482z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M276.197 333.07L283.167 329.936 274.125 300.959 263.837 305.586 276.197 333.07z"
      ></path>
      <path
        fill="#2f2e41"
        d="M282.269 316.743l-13.125 7.432-32.368-52.013c-22.723 15.308-44.942 22.64-67.342 26.551-7.573 1.349-15.294.665-16.748-7.043q-.102-.54-.163-1.087c-.894-8.42 9.579-19.21 10.91-29.512l28.663-1.72 3.535 4.531 33.147-20.38a14.23 14.23 0 0119.583 4.952q.33.541.61 1.11z"
      ></path>
      <path
        fill="#2f2e41"
        d="M274.236 351.733l-15.018 1.395-27.23-55.689c-27.002 4.644-52.475 6.132-74.51.514a14.242 14.242 0 01-10.184-17.205q.128-.535.297-1.058c2.637-8.046 9.54-13.739 14.978-22.588l39.725.604-4.437 15.309 38.59-4.994a14.23 14.23 0 0115.83 12.547q.078.628.101 1.261z"
      ></path>
      <circle cx="177.853" cy="149.546" r="16.13" fill="#ffb6b6"></circle>
      <path
        fill="#0fd24f"
        d="M168.397 171.5l14.79 1.74a3.018 3.018 0 012.595 2.353l2.353 10.763 14.662 17.266s5.265 9.872 3.29 21.06-2.842 19.502-2.842 19.502-39.33 14.747-40.647 12.773-1.744-10.943-1.744-10.943l-5.265-58.574s5.924-9.872 9.214-9.872c2.124-.072 1.852-4.851 3.594-6.068z"
      ></path>
      <ellipse
        cx="203.645"
        cy="243.279"
        fill="#0fd24f"
        rx="3.755"
        ry="4.506"
      ></ellipse>
      <path
        fill="#2f2e41"
        d="M261.736 364.684l11.431-5.729 2.154 5.027 14.455 2.922a3.416 3.416 0 01.67 6.487l-13.967 5.987-4.538-3.94 1.191 5.375-5.266 2.257zM272.925 330.66l11.329-5.93 2.242 4.988 14.505 2.667a3.416 3.416 0 01.783 6.475l-13.86 6.232-4.606-3.86 1.285 5.353-5.225 2.35zM168.637 144.486a20.642 20.642 0 01-11.907-3.756l-.116-.082v-.142a15.566 15.566 0 0115.548-15.547h6.546a15.566 15.566 0 0115.548 15.547 3.62 3.62 0 01-3.54 3.603l-21.711.374-.368.003z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M233.564 269.96a7.553 7.553 0 01-8.222-8.157l-53.702-28.779 11.746-7.52 50.666 29.382a7.594 7.594 0 01-.488 15.073z"
      ></path>
      <path
        fill="#0fd24f"
        d="M163.26 180.138s-9.583-1.2-12.043 9.58 14.4 40.832 16.973 45.757 37.633 15.696 37.633 15.696l13.5-7.051-30.229-19.158z"
      ></path>
      <path
        fill="#2f2e41"
        d="M180.35 127.362c1.509-1.85.329-4.591-.909-6.634q-4.253-7.019-8.93-13.77c-3.895-5.624-8.615-11.503-15.322-12.852-6.117-1.23-12.28 1.678-17.542 5.03-5.174 3.295-10.27 7.446-12.239 13.256-1.884 5.56-.56 11.734 1.784 17.116 2.008 4.609 4.71 10.12 1.846 14.253a11.057 11.057 0 01-5.296 3.605 37.833 37.833 0 01-16.63 2.453c7.767-.083 14.566 7.892 22.096 5.984 4.27-1.082 7.113-5.062 9.16-8.963a25.11 25.11 0 002.989-8.466c.497-4.04-.579-8.292.78-12.13 1.414-3.993 5.172-6.665 8.966-8.552 2.518-1.252 5.264-2.298 8.07-2.086 7.95.602 12.069 10.388 19.613 12.969"
      ></path>
      <path
        fill="#0fd24f"
        d="M450.589 181.98h-158.14a4.161 4.161 0 01-4.157-4.157v-64.167a4.161 4.161 0 014.157-4.156h158.14a4.161 4.161 0 014.156 4.156v64.167a4.161 4.161 0 01-4.156 4.156z"
      ></path>
      <path
        fill="#e4e4e4"
        d="M435.531 212.76H307.507a4.988 4.988 0 010-9.976h128.024c6.433-.09 6.497 10.067 0 9.976zM435.531 235.622H307.507a4.988 4.988 0 110-9.976h128.024c6.433-.09 6.497 10.066 0 9.976zM435.531 258.483H307.507a4.988 4.988 0 010-9.976h128.024c6.433-.09 6.497 10.067 0 9.976z"
      ></path>
      <path
        fill="#e6e7e8"
        d="M619.342 23.389H89.6a2.97 2.97 0 01-2.967-2.968V2.967A2.97 2.97 0 0189.6 0h529.742a2.97 2.97 0 012.967 2.967v17.454a2.97 2.97 0 01-2.967 2.968z"
      ></path>
      <circle
        cx="103.853"
        cy="10.843"
        r="4.213"
        fill="#fff"
        data-name="Ellipse 90"
      ></circle>
      <circle
        cx="119.843"
        cy="10.843"
        r="4.213"
        fill="#fff"
        data-name="Ellipse 91"
      ></circle>
      <circle
        cx="135.834"
        cy="10.843"
        r="4.213"
        fill="#fff"
        data-name="Ellipse 92"
      ></circle>
      <path
        fill="#fff"
        d="M603.675 7.943H585.26a1.364 1.364 0 010-2.727h18.414a1.364 1.364 0 010 2.727zM603.675 13.058H585.26a1.364 1.364 0 010-2.728h18.414a1.364 1.364 0 110 2.728zM603.675 18.173H585.26a1.364 1.364 0 110-2.728h18.414a1.364 1.364 0 110 2.728z"
      ></path>
    </svg>
  );
}

export default Social;