import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import displayData from "./displayData";
import axios from "axios";

//Import Containers
import Container from "../../components/Container";
import Heading from "../../components/Heading";
import P from "../../components/P";

function DinamicCards() {
  return (
    <Container margin="3rem 0 0" className="dinamic_cards">
      <Container
        flex
        alignItems="flex-start"
        tabletAlignItems="center"
        tabletFlexDirection="column"
        laptopFlexDirection="row"
        flexWrap="wrap"
        justifyContent="space-evenly"
      >
        {displayData.map((item, key) => {
          return (
            <Container
              key={key}
              className="cards"
              position="relative"
              flex
              laptopFlexDirection="row"
              margin="1rem 0"
              width="100%"
              tabletWidth="70%"
              laptopWidth="45%"
              laptopLWidth="45%"
              maxHeight="350px"
              height="350px"
              minHeight="155px"
              justifyContent="center"
              alignItems="center"
              borderRadius="25px"
              border="2px solid grey"
            >
              <Container
                width="20%"
                textAlign="center"
                position="relative"
                top="-2rem"
                padding="1.5rem"
                className="icon_display"
              >
                <img
                  style={{ width: "50px", height: "50px" }}
                  src={item.icon}
                ></img>
              </Container>
              <Container
                width="80%"
                flex
                flexDirection="column"
                padding="0.5rem"
              >
                <Container width="80%">
                  <Heading level="1.2">{item.title}</Heading>
                </Container>
                <Container>
                  <P>{item.text}</P>
                </Container>
                <Container textAlign="left">
                  <Link
                    to={`/solucoes${item.path}`}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    Ver mais
                  </Link>
                </Container>
              </Container>
              <Container
                className="corner"
                position="absolute"
                width="50px"
                height="50px"
                borderBottomLeftRadius="50px "
                borderTopRightRadius="23px"
                backgroundColor="#0fd24f"
                top="0"
                right="0"
              ></Container>
            </Container>
          );
        })}
      </Container>
    </Container>
  );
}

export default DinamicCards;
