import React from "react";
import { Link } from "react-router-dom";

import "./CTA.css";

import Container from "../../components/Container";
import Heading from "../../components/Heading";

const CTA = () => {
  return (
    <Container backgroundColor="#0fd24f" padding="4rem 0">
      <Container
        width="100%"
        flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Container width="100" tabletWidth="80%">
          <Heading
            textAlign="center"
            levelTablet="0.7"
            level="1.1"
            fontWeight="600"
            margin="2% 0 0"
            textColor="#ffffff"
          >
            Quer mudar a sua empresa?
          </Heading>
          <Heading
            textAlign="center"
            levelTablet="0.8"
            level="1.2"
            fontWeight="600"
            textColor="white"
          >
            Veja as nossas soluções.
          </Heading>
        </Container>
      </Container>
    </Container>
  );
};

export default CTA;
