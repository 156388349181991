import React from "react";

//Import Components
import Container from "../../components/Container";
import Heading from "../../components/Heading";
import P from "../../components/P";

function Display(props) {
  return (
    <Container className="mosaic" laptopPadding="7rem">
      <Container
        flex
        tabletAlignItems="center"
        tabletFlexDirection="column"
        laptopFlexDirection="row"
        flexWrap="wrap"
        justifyContent="space-evenly"
      >
        {props.displayData.map((item, key) => {
          return (
            <Container
              key={key}
              className="cards"
              position="relative"
              flex
              laptopFlexDirection="row"
              margin="1rem 0"
              width="80%"
              tabletWidth="50%"
              laptopWidth="40%"
              laptopLWidth="30%"
              minHeight="155px"
              justifyContent="center"
              alignItems="center"
              borderRadius="25px"
              border="2px solid grey"
            >
              <Container
                width="15%"
                flex
                justifyContent="center"
                position="relative"
                className="icon_display"
              >
                <img
                  src={item.icon}
                  style={{ width: "60px", height: "60px" }}
                ></img>
              </Container>
              <Container width="60%">
                <Container flex flexDirection="column" padding="2rem">
                  <Container>
                    <Heading level="1.2" levelTablet="1" levelLaptop="2.5">
                      {item.title}
                    </Heading>
                  </Container>
                </Container>
              </Container>
              <Container
                className="corner"
                position="absolute"
                width="50px"
                height="50px"
                borderBottomLeftRadius="50px "
                borderTopRightRadius="23px"
                backgroundColor="#0fd24f"
                top="0"
                right="0"
              ></Container>
            </Container>
          );
        })}
      </Container>
    </Container>
  );
}

export default Display;
