import React from "react";

import cardsData from "./cardsData";
import Cards from "../../sections/cards";

import DinamicCards from "../../sections/dinamic_cards";

import Container from "../../components/Container";
import Heading from "../../components/Heading";
import DefaultPage from "../../containers/DefaultPage";
import solucoesItems from "./solucoesdata";

const Solucoes = () => {
  return (
    <Container>
      <Container
        padding="250px 0 0"
        flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <DefaultPage
          heading={solucoesItems.heading}
          paragraph={solucoesItems.paragraph}
        />
      </Container>
      <Container flex alignItems="center" justifyContent="center">
        <Container
          laptopLWidth="70%"
          laptopWidth="80%"
          tabletWidth="85%"
          width="95%"
          flex
          flexDirection="column"
        >
          <Container margin="3rem 0">
            <Heading level="0.7" className="solucoes-heading" fontWeight="800">
              Os Nossos Serviços
            </Heading>
            <Cards cardsData={cardsData} underline="solucoes-underline" />
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default Solucoes;
