import React, { useLayoutEffect } from "react";

import Container from "../../components/Container";
import Heading from "../../components/Heading";
import P from "../../components/P";

const DefaultPage = (props) => {
  function createMarkup() {
    return { __html: props.heading };
  }
  return (
    <Container
      laptopLWidth="70%"
      laptopWidth="80%"
      tabletWidth="85%"
      width="95%"
    >
      <Heading
        dangerouslySetInnerHTML={createMarkup()}
        id="section-heading"
        level="0.7"
        levelTablet="0.5"
        laptopLevel="0.4"
        fontWeight="800"
      ></Heading>
      <P margin="3rem 0">{props.paragraph}</P>
    </Container>
  );
};

export default DefaultPage;
